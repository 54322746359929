export const DeliveryOrderPackageStatus = {
  UNDEFINED: -1,
  CANCELLED: 0,
  PENDING: 1,
  PREPARING: 2,
  OUT_FOR_DELIVERY: 3,
  DELIVERY_FAILED: 4,
  DELIVERED: 5,
};

export class DeliveryOrderPackage {
  constructor() {
    /** @type number */
    this.id = 0;

    /** @type ?number */
    this.deliveryOrderId = null;

    /** @type string */
    this.code = '';

    /** @type string */
    this.clientName = '';

    /** @type ClientOrderProduct[] */
    this.products = [];

    /** @type ClientOrderDelivery */
    this.address = null;

    /** @type number */
    this.regStatus = DeliveryOrderPackageStatus.UNDEFINED;

    /** @type number */
    this.boxNumber = 0;

    /** @type number */
    this.length = 0;

    /** @type number */
    this.width = 0;

    /** @type number */
    this.height = 0;

    /** @type number */
    this.weight = 0;

    /** @type ?number */
    this.latitude = null;

    /** @type ?number */
    this.longitude = null;

    /** @type ?number */
    this.packageOrder = null;
  }

  get isWarehousePackage() {
    return !Number(this.latitude) && !Number(this.longitude);
  }

  get productsQty() {
    if (!this.products.length) return 0;
    return this.products.reduce((acc, product) => acc + product.quantity, 0);
  }

  get calculatedWeight() {
    if (!this.products.length) return 0;

    return this.products.reduce((acc, product) => acc + product.totalWeight, 0);
  }
}
