import { ClientOrder } from '@/models/delivery/ClientOrder';
import { ClientOrderProduct } from '@/models/delivery/ClientOrderProduct';
import { makeErrorResponseFromData } from '@/serializers/General.serializer';
import { ClientOrderDelivery } from '@/models/delivery/ClientOrderDelivery';
import { ClientOrderProductPreparation } from '@/models/delivery/ClientOrderProductPreparation';
import { DeliveryOrderPackage } from '@/models/delivery/DeliveryOrderPackage';
import { DeliveryOrder } from '@/models/delivery/DeliveryOrder';
import { ClientOrderPayment } from '@/models/delivery/ClientOrderPayment';

/**
 * @param {Object} data
 * @returns {ClientOrderProduct}
 */
export function makeClientOrderProductFromData(data) {
  const {
    id,
    product,
    alreadyPackaged,
    quantity,
  } = data;

  const inst = new ClientOrderProduct();

  inst.id = id;
  inst.alreadyPackaged = alreadyPackaged;
  inst.quantity = quantity;
  inst.productId = product.id;
  inst.productName = product.name;
  inst.productMeasuringUnitName = product.measuringUnitName ?? '';
  inst.productLocation = product.location ?? '';
  inst.productBarcode = product.barcode;
  inst.imageUrl = product.image;
  inst.productWeight = product.weight;

  return inst;
}

/**
 * @param {Object} data
 * @returns {ClientOrderDelivery}
 */
export function makeClientOrderDeliveryFromData(data) {
  const {
    id,
    addressMain,
    addressOptional,
    cityName,
    stateName,
    zipCode,
    deliveryType,
    email,
    phoneNumber,
    contactName,
    companyName,
  } = data;
  const inst = new ClientOrderDelivery();

  inst.id = id;
  inst.addressMain = addressMain;
  inst.addressOptional = addressOptional;
  inst.cityName = cityName;
  inst.stateName = stateName;
  inst.zipCode = zipCode;
  inst.deliveryType = deliveryType;
  inst.email = email;
  inst.phoneNumber = phoneNumber;
  inst.contactName = contactName;
  inst.companyName = companyName;

  return inst;
}

/**
 * @param {Object} data
 * @returns {ClientOrderPayment}
 */
export function makeClientOrderPaymentFromData(data) {
  const {
    id,
    type,
    code,
    total,
  } = data;
  const inst = new ClientOrderPayment();

  inst.id = id;
  inst.type = type;
  inst.code = code;
  inst.total = total;

  return inst;
}

/**
 * @param {Object} data
 * @param {int} boxNumber
 * @param {ClientOrderProduct[]} coProducts
 * @param {string} clientName
 * @param {ClientOrderDelivery} delivery
 * @returns {DeliveryOrderPackage}
 */
export function makeDeliveryOrderPackageFromClientOrderData(
  data,
  boxNumber,
  coProducts,
  clientName,
  delivery,
) {
  const {
    id,
    code,
    length,
    width,
    height,
    weight,
    products,
  } = data;

  const inst = new DeliveryOrderPackage();

  inst.id = id;
  inst.clientName = clientName;
  inst.code = code;
  inst.length = length;
  inst.width = width;
  inst.height = height;
  inst.weight = weight;
  inst.boxNumber = boxNumber;
  inst.address = delivery;

  // search each package product in client order products and append to package
  inst.products = products.map((product) => {
    const productData = coProducts.find(item => item.id === product.clientOrderProductId);

    const newInst = productData.clone();
    newInst.quantity = Number(product.quantity);

    return newInst;
  });

  return inst;
}

export function makeClientOrderFromData(data) {
  const {
    id,
    subtotal,
    taxes,
    shipping,
    total,
    createdAt,
    regStatus,
    client,
    products,
    delivery,
    payment,
    packages,
  } = data;

  const inst = new ClientOrder();

  inst.id = id;
  inst.clientName = client.name;
  inst.subtotal = subtotal;
  inst.taxes = taxes;
  inst.shipping = shipping;
  inst.total = total;
  inst.createdAt = createdAt;
  inst.regStatus = regStatus;
  inst.products = products.map(item => makeClientOrderProductFromData(item));
  inst.delivery = makeClientOrderDeliveryFromData(delivery);
  inst.payment = makeClientOrderPaymentFromData(payment);

  if (packages) {
    inst.packages = packages.map(
      (item, idx) => makeDeliveryOrderPackageFromClientOrderData(
        item,
        idx + 1,
        inst.products,
        inst.clientName,
        inst.delivery,
      ),
    );
  }

  return inst;
}

/**
 * @param {Object} data
 * @param {Object} headers
 * @param {number} status
 * @returns {ClientOrder[]}
 */
export function getDeliveryClientsOrdersSerializer(data, headers, status) {
  // if not object, return same data to next transform function
  if (typeof data !== 'object') {
    return data;
  }

  // process error
  if (status >= 400 && status < 500) {
    return makeErrorResponseFromData(data, headers, status);
  }

  return data.data.map(item => makeClientOrderFromData(item));
}

/**
 * @param {!number} clientOrderId
 * @param {ClientOrderProduct} item
 * @returns {ClientOrderProductPreparation}
 */
export function makeClientOrderProductPreparationFromArgs(clientOrderId, item) {
  const inst = new ClientOrderProductPreparation();

  const {
    id,
    productId,
    productName,
    productMeasuringUnitName,
    productLocation,
    productBarcode,
    imageUrl,
    quantity,
    alreadyPackaged,
    productWeight,
  } = item;

  const requestedQuantity = quantity - alreadyPackaged;

  inst.clientOrderId = clientOrderId;
  inst.clientOrderProductId = id;
  inst.productId = productId;
  inst.barcode = productBarcode;
  inst.name = productName;
  inst.measuringUnitName = productMeasuringUnitName;
  inst.location = productLocation;
  inst.imageUrl = imageUrl;
  inst.requestedQuantity = requestedQuantity < 0 || Number.isNaN(requestedQuantity) ? 0 : requestedQuantity;
  inst.weight = productWeight;

  return inst;
}

/**
 * @param {Object} data
 * @return {DeliveryOrderPackage}
 */
export function makeDeliveryOrderPackageFromData(data) {
  const {
    id,
    deliveryOrderId,
    code,
    client,
    regStatus,
    address,
    products,
    latitude,
    longitude,
  } = data;

  const clientName = !!client.companyName ? `${client.companyName.trim()} (${client.name.trim()})` : client.name.trim();

  const inst = new DeliveryOrderPackage();

  inst.id = id;
  inst.deliveryOrderId = deliveryOrderId;
  inst.code = code;
  inst.clientName = clientName;
  inst.regStatus = regStatus;
  inst.address = makeClientOrderDeliveryFromData(address);
  inst.products = !products ? [] : products.map(item => makeClientOrderProductFromData(item));
  inst.latitude = latitude;
  inst.longitude = longitude;
  return inst;
}

/**
 * @param data
 */
export function makeDeliveryOrderFromData(data) {
  const {
    id,
    createdAt,
    deliveryDate,
    dispatchedAt,
    finishedAt,
    regStatus,
    packages,
  } = data;

  const inst = new DeliveryOrder();

  inst.id = id;
  inst.createdAt = createdAt;
  inst.deliveryDate = deliveryDate;
  inst.dispatchedAt = dispatchedAt;
  inst.finishedAt = finishedAt;
  inst.regStatus = regStatus;

  inst.packages = packages.map(item => makeDeliveryOrderPackageFromData(item));

  return inst;
}

/**
 * @param {Object} data
 * @param {Object} headers
 * @param {number} status
 * @returns {DeliveryOrder[]}
 */
export function getDeliveryOrdersSerializer(data, headers, status) {
  // if not object, return same data to next transform function
  if (typeof data !== 'object') {
    return data;
  }

  // process error
  if (status >= 400 && status < 500) {
    return makeErrorResponseFromData(data, headers, status);
  }

  return data.data.map(item => makeDeliveryOrderFromData(item));
}

/**
 * @param {Object} data
 * @param {Object} headers
 * @param {number} status
 * @returns {DeliveryOrderPackage[]}
 */
export function getDeliveryOrdersPackagesSerializer(data, headers, status) {
  // if not object, return same data to next transform function
  if (typeof data !== 'object') {
    return data;
  }

  // process error
  if (status >= 400 && status < 500) {
    return makeErrorResponseFromData(data, headers, status);
  }

  return data.data.map(item => makeDeliveryOrderPackageFromData(item));
}


/**
 * @param {ClientOrderProductPreparation} item
 * @returns ClientOrderProduct
 */
export function makeClientOrderProductFromProductPrepItem(item) {
  const inst = new ClientOrderProduct();

  inst.id = item.clientOrderProductId;
  inst.quantity = Number(item.readyQuantity);
  inst.productId = item.productId;
  inst.productName = item.name;
  inst.productMeasuringUnitName = item.measuringUnitName;
  inst.productLocation = item.location;
  inst.productBarcode = item.barcode;
  inst.productWeight = item.weight;
  inst.imageUrl = item.imageUrl;

  return inst;
}

/**
 * @param {Object} data
 * @param {Object} headers
 * @param {number} status
 * @returns {ClientOrder}
 */
export function getDeliveryClientOrderPackingSerializer(data, headers, status) {
  // if not object, return same data to next transform function
  if (typeof data !== 'object') {
    return data;
  }

  // process error
  if (status >= 400 && status < 500) {
    return makeErrorResponseFromData(data, headers, status);
  }

  return makeClientOrderFromData(data.data);
}
