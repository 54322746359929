export class UserAddress {
  constructor() {
    this.id = '';

    this.addressMain = '';
    this.addressOptional = '';
    this.companyName = '';
    this.contactName = '';
    this.email = '';
    this.phoneNumber = '';

    this.cityName = '';
    this.stateCode = '';
    this.stateName = '';
    this.zipCode = '';
  }

  get listLabel() {
    return `${this.addressFull}, ${this.addressComplementary}`;
  }

  get addressFull() {
    const addressOptional = this.addressOptional ?? '';
    if (!addressOptional) {
      return this.addressMain;
    }

    return `${this.addressMain}, ${this.addressOptional}`;
  }

  get addressComplementary() {
    // not local or remote address, return empty
    if (!this.zipCode || Number(this.zipCode) === 0) {
      return '';
    }

    return `${this.cityName ?? ''}, ${this.stateName ?? ''} ${this.zipCode ?? ''}`.trim();
  }

  get contact() {
    if (this.companyName) {
      return `${this.companyName} (${this.contactName})`;
    }

    return this.contactName;
  }
}
