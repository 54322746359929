export class ClientOrderProductPreparation {
  constructor() {
    /** @type ?number */
    this.packageId = null;

    /** @type ?string */
    this.packageCode = null;

    /** @type number */
    this.productId = 0;

    /** @type number */
    this.clientOrderId = 0;

    /** @type number */
    this.clientOrderProductId = 0;

    /** @type string */
    this.barcode = '';

    /** @type string */
    this.name = '';

    /** @type string */
    this.measuringUnitName = '';

    /** @type string */
    this.location = '';

    /** @type string */
    this.imageUrl = '';

    /** @type number */
    this.readyQuantity = 0;

    /** @type number */
    this.requestedQuantity = 0;

    /** @type ?number */
    this.weight = null;
  }

  clone() {
    const inst = new ClientOrderProductPreparation();

    inst.packageId = this.packageId;
    inst.packageCode = this.packageCode;
    inst.productId = this.productId;
    inst.clientOrderId = this.clientOrderId;
    inst.clientOrderProductId = this.clientOrderProductId;
    inst.barcode = this.barcode;
    inst.name = this.name;
    inst.measuringUnitName = this.measuringUnitName;
    inst.location = this.location;
    inst.imageUrl = this.imageUrl;
    inst.readyQuantity = this.readyQuantity;
    inst.requestedQuantity = this.requestedQuantity;
    inst.weight = this.weight;

    return inst;
  }
}
