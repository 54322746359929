import Axios, { makeAxiosConfig } from '@/services/Axios';
import {
  getClientListItemSerializer,
  getProductListItemSerializer,
  getUserAddressSerializer,
} from '@/serializers/Pos.serializer';

export default {
  async getProductsAutocompleteData(query, limit, storeId, clientId) {
    const config = makeAxiosConfig({}, getProductListItemSerializer);

    config.params = {
      query,
      limit,
      storeId,
      clientId,
    };

    const response = await Axios.get('/product/autocomplete', config);

    return response.data;
  },

  async getClientsAutocompleteData(query = '') {
    const config = makeAxiosConfig({}, getClientListItemSerializer);

    config.params = {
      query,
    };

    const response = await Axios.get('/client/autocomplete', config);

    return response.data;
  },

  async saveClientOrder(requestData) {
    const config = makeAxiosConfig({});

    const response = await Axios.post('/client-order/pos', requestData, config);

    return response.data;
  },

  async taxes(requestData) {
    const config = makeAxiosConfig({});

    const response = await Axios.post('/shipping-cost/calculate', requestData, config);

    return response.data.data;
  },

  /**
   * @param userId {number}
   * @returns {Promise<UserAddress[]>}
   */
  async getUserAddress(userId) {
    const config = makeAxiosConfig({}, getUserAddressSerializer);

    const response = await Axios.get(`/user-address?user_id=${userId}`, config);

    return response.data;
  },
};
