import { makeErrorResponseFromData } from '@/serializers/General.serializer';
import { ClientListItem } from '@/models/ClientListItem';
import { CartItem } from '@/models/pos/CartItem';
import { ProductListItem } from '@/models/pos/ProductListItem';
import { UserAddress } from '@/models/pos/UserAddress';

/**
 * @param {ProductListItem} data
 * @returns {CartItem}
 */
export function makeCartItemFromProductListItem(data) {
  const inst = new CartItem();

  inst.id = data.id;
  inst.quantity = 1;
  inst.name = data.name;
  inst.model = data.model;
  inst.brand = data.brandName;
  inst.baseCost = data.baseCost;
  inst.realPrice = data.realPrice;
  inst.discountRate = data.discountRate;
  inst.image = data.image;
  inst.measuringUnitName = data.measuringUnit;

  return inst;
}

export function makeProductListItemFromData(data) {
  const {
    id,
    name,
    barcode,
    baseCost,
    images,
    model,
    measuringUnit,
    brand,
    realPrice,
    discountRate,
  } = data;

  return new ProductListItem(
    id,
    name,
    barcode,
    baseCost,
    images,
    model,
    measuringUnit,
    brand,
    realPrice,
    discountRate,
  );
}

export function makeClientListItemFromData(data) {
  const {
    id,
    name,
    discount,
    email,
    phoneNumber,
  } = data;

  return new ClientListItem(id, name, discount, email, phoneNumber);
}

/**
 * @param {number} clientUserId
 * @param {number} subtotal
 * @param {number} taxes
 * @param {number} shipping
 * @param {CartItem[]} productsData
 * @param {PosPayment[]} paymentsData
 * @param {UserAddress} posAddress
 * @returns {{}}
 */
export function makeClientOrderPosRequestDataFromArgs(
  clientUserId,
  subtotal,
  taxes,
  shipping,
  productsData,
  paymentsData,
  posAddress,
) {
  const products = productsData.map(item => ({
    product_id: item.id,
    quantity: item.quantity,
    unit_price: item.baseCost,
    total: item.total,
  }));

  const payments = paymentsData.map(item => ({
    type: item.paymentMethod,
    code: item.code,
    total: Number(item.paymentAmount),
  }));

  const delivery = !posAddress ? null : {
    company_name: posAddress.companyName,
    contact_name: posAddress.contactName,
    contact_email: posAddress.email,
    contact_phone: posAddress.phoneNumber,
    address_main: posAddress.addressMain,
    address_extended: posAddress.addressOptional,
    city: posAddress.cityName,
    state: posAddress.stateCode,
    zip_code: posAddress.zipCode,
  };

  return {
    subtotal,
    taxes,
    shipping,
    products,
    payments,
    delivery,
    client_user_id: clientUserId,
  };
}

/**
 * @param data {Object}
 * @returns {UserAddress}
 */
export function makeUserAddressFromData(data) {
  const {
    addressMain,
    addressOptional,
    city_name: cityName,
    companyName,
    contactName,
    email,
    id,
    phoneNumber,
    state_code: stateCode,
    state_name: stateName,
    zipCode,
  } = data;

  const inst = new UserAddress();
  inst.id = id;
  inst.addressMain = addressMain;
  inst.addressOptional = addressOptional;
  inst.companyName = companyName?.trim() ?? '';
  inst.contactName = contactName?.trim() ?? '';
  inst.email = email;
  inst.phoneNumber = phoneNumber;
  inst.cityName = cityName;
  inst.stateCode = stateCode;
  inst.stateName = stateName;
  inst.zipCode = zipCode;

  return inst;
}

/**
 * @param {Object} data
 * @param {Object} headers
 * @param {number} status
 * @returns {DeliveryOrderPackage[]}
 */
export function getProductListItemSerializer(data, headers, status) {
  // if not object, return same data to next transform function
  if (typeof data !== 'object') {
    return data;
  }

  // process error
  if (status >= 400 && status < 500) {
    return makeErrorResponseFromData(data, headers, status);
  }

  return data.data.map(item => makeProductListItemFromData(item));
}

/**
 * @param {Object} data
 * @param {Object} headers
 * @param {number} status
 * @returns {DeliveryOrderPackage[]}
 */
export function getClientListItemSerializer(data, headers, status) {
  // if not object, return same data to next transform function
  if (typeof data !== 'object') {
    return data;
  }

  // process error
  if (status >= 400 && status < 500) {
    return makeErrorResponseFromData(data, headers, status);
  }

  const mappedItems = data.data.map(item => makeClientListItemFromData(item));

  return [
    // new ClientListItem(0, 'Unknown', 0),
    ...mappedItems,
  ];
}


/**
 * @param {Object} data
 * @param {Object} headers
 * @param {number} status
 * @returns {DeliveryOrderPackage[]}
 */
export function getUserAddressSerializer(data, headers, status) {
  // if not object, return same data to next transform function
  if (typeof data !== 'object') {
    return data;
  }

  // process error
  if (status >= 400 && status < 500) {
    return makeErrorResponseFromData(data, headers, status);
  }

  return data.data.map(item => makeUserAddressFromData(item));
}
