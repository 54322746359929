export default {
  /**
   * @return {string}
   */
  getPrinterEndpointFromMeta() {
    const metaElement = document.querySelector('meta[name="ens:pe"]');
    return metaElement.getAttribute('content');
  },

  /**
   * @param {!number} clientOrderId
   * @param {!DeliveryOrderPackage} doPackage
   * @param {!number} actualPackage
   * @param {!number} totalPackages
   * @return {string}
   */
  makeZplFromPosDeliveryOrderPackage(clientOrderId, doPackage, actualPackage, totalPackages) {
    const {
      clientName,
      address,
      code,
    } = doPackage;

    const clientOrderCode = `ORDER #${clientOrderId.toString().padStart(6, '0')}`;

    return `^XA
^LRN^MNY^MFN,N^LH10,12^MCY^POI^PW812^CI27

^FX label number when more than one package
^FT673,34^A0N,28,32^FV${actualPackage} OF ${totalPackages}^FS

^FX from (company data) part
^FT15,40^A0N,35,39^FVEASY NAIL SUPPLY^FS
^FT15,72^A0N,26,30^FV1311 Price Plaza Dr^FS
^FT15,102^A0N,26,30^FVKaty, TX 77449^FS
^FT15,132^A0N,26,30^FVcontact@easynailsupply.com^FS

^FX ship to part
^FT15,243^A0N,40,44^FVSHIP TO: ^FS
^FT60,285^A0N,30,34^FV${clientName.toUpperCase()}^FS
^FT60,320^A0N,30,34^FV${address.addressMain.toUpperCase()}^FS
^FT60,355^A0N,30,34^FV${address.addressComplementary.toUpperCase()}^FS

^FX delivery classification
^FT55,587^A0N,175,220^FVP^FS
^FT290,517^A0N,70,60^FVEASY NAIL SUPPLY^FS
^FT290,587^A0N,70,60^FVPOS ORDER^FS

^FX client order number
^FT0,1080^A0N,41,35^FB812,1,0,C^FH\\^FD${clientOrderCode}^FS

^FX delivery order code
^FO240,680
^BQN,2,10
^FDQA,${code}^FS

^FX delivery type lines
^FO0,416^GB800,4,4^FS
^FO240,416^GB3,221,3^FS

^FX qr code section lines
^FO0,637^GB798,14,14^FS
^FO0,1100^GB800,14,14^FS

^XZ
`;
  },

  /**
   * @param {!number} clientOrderId
   * @param {!DeliveryOrderPackage} doPackage
   * @param {!number} actualPackage
   * @param {!number} totalPackages
   * @return {string}
   */
  makeZplFromDeliveryOrderPackage(clientOrderId, doPackage, actualPackage, totalPackages) {
    const {
      clientName,
      address,
      code,
    } = doPackage;

    const clientOrderCode = `ORDER #${clientOrderId.toString().padStart(6, '0')}`;

    return `^XA
^LRN^MNY^MFN,N^LH10,12^MCY^POI^PW812^CI27

^FX label number when more than one package
^FT673,34^A0N,28,32^FV${actualPackage} OF ${totalPackages}^FS

^FX from (company data) part
^FT15,40^A0N,35,39^FVEASY NAIL SUPPLY^FS
^FT15,72^A0N,26,30^FV1311 Price Plaza Dr^FS
^FT15,102^A0N,26,30^FVKaty, TX 77449^FS
^FT15,132^A0N,26,30^FVcontact@easynailsupply.com^FS

^FX ship to part
^FT15,243^A0N,40,44^FVSHIP TO: ^FS
^FT60,285^A0N,30,34^FV${clientName.toUpperCase()}^FS
^FT60,320^A0N,30,34^FV${address.addressMain.toUpperCase()}^FS
^FT60,355^A0N,30,34^FV${address.addressComplementary.toUpperCase()}^FS

^FX delivery classification
^FT55,587^A0N,175,220^FVL^FS
^FT290,517^A0N,70,60^FVEASY NAIL SUPPLY^FS
^FT290,587^A0N,70,60^FVLOCAL DELIVERY^FS

^FX client order number
^FT0,1080^A0N,41,35^FB812,1,0,C^FH\\^FD${clientOrderCode}^FS

^FX delivery order code
^FO240,680
^BQN,2,10
^FDQA,${code}^FS

^FX delivery type lines
^FO0,416^GB800,4,4^FS
^FO240,416^GB3,221,3^FS

^FX qr code section lines
^FO0,637^GB798,14,14^FS
^FO0,1100^GB800,14,14^FS

^XZ
`;
  },

  /**
   * @param {ClientOrderProduct[]} products
   * @param {!number} actualPackage
   * @param {!number} totalPackages
   * @return {string}
   */
  makeZplPckListFromProducts(products, actualPackage, totalPackages) {
    const productsZplRows = [];
    let startY = 210;

    // eslint-disable-next-line no-restricted-syntax
    for (const product of products) {
      const productName = product.productName.substring(0, 45);
      productsZplRows.push(`^FO100,${startY}^A0N,30,30^FD${product.quantity}^FS
^FO210,${startY}^A0N,30,30^FD${productName}^FS
`);

      startY += 50;
    }

    const productsList = productsZplRows.length > 0 ? productsZplRows.join('\n') : '';

    return `^XA
^LRN^MNY^MFN,N^LH10,12^MCY^POI^PW812^CI27

^FX label number when more than one package
^FT673,34^A0N,28,32^FV${actualPackage} OF ${totalPackages}^FS

^FX Title
^FT15,40^A0N,35,39^FVEASY NAIL SUPPLY PACKING LIST^FS

^FO100,140^A0N,40,40^FDQTY^FS
^FO210,140^A0N,40,40^FDITEM^FS

${productsList}

^XZ
`;
  },

  makeZplForTest() {
    return `^XA
^LRN^MNY^MFN,N^LH10,12^MCY^POI^PW812^CI27

^FX label number when more than one package
^FT673,34^A0N,28,32^FV1 OF 1^FS

^FX from (company data) part
^FT15,40^A0N,35,39^FVEASY NAIL SUPPLY^FS
^FT15,72^A0N,26,30^FV1311 Price Plaza Dr^FS
^FT15,102^A0N,26,30^FVKaty, TX 77449^FS
^FT15,132^A0N,26,30^FVcontact@easynailsupply.com^FS

^FX ship to part
^FT15,243^A0N,40,44^FVSHIP TO: ^FS
^FT60,285^A0N,30,34^FVClient Name^FS
^FT60,320^A0N,30,34^FVClient Main Address^FS
^FT60,355^A0N,30,34^FVClient Complementary Address^FS

^FX delivery classification
^FT55,587^A0N,175,220^FVL^FS
^FT290,517^A0N,70,60^FVEASY NAIL SUPPLY^FS
^FT290,587^A0N,70,60^FVLOCAL DELIVERY^FS

^FX delivery order code
^FO240,680
^BQN,2,10
^FDQA,1^FS

^FX delivery type lines
^FO0,416^GB800,4,4^FS
^FO240,416^GB3,221,3^FS

^FX qr code section lines
^FO0,637^GB798,14,14^FS
^FO0,1057^GB800,14,14^FS

^XZ
`;
  },
};
