/* eslint no-extra-boolean-cast: 0 */
/* eslint array-callback-return: 0 */
/* eslint consistent-return: 0 */
/* eslint arrow-parens: 0 */

import CurrencyFormatter from '@/models/CurrencyFormatter';

/**
 * @param {PurchaseOrderProductItem[]} items
 * @returns {number}
 */
function transferTaxesAmountFromPurchaseOrder(items) {
  items.forEach((x) => {
    const item = x;
    item.alreadyInList = false;
  });

  let transferTaxesArr = items.map((x) => {
    let quantity = 0;

    // if not in list, add... else, ignore
    if (!x.alreadyInList) {
      // eslint-disable-next-line object-curly-newline, max-len
      const { comboId, product } = x;
      const { id: currentId } = product;

      // check for same items in array
      // set quantity property for next operations
      if (x.isGift) {
        // eslint-disable-next-line max-len
        const giftItemsWithSameComboId = items.filter(item => item.comboId === comboId && item.product.id === currentId);
        quantity = giftItemsWithSameComboId.length;
        giftItemsWithSameComboId.forEach((item) => {
          const listItem = item;
          listItem.alreadyInList = true;
        });
      } else {
        const totalItems = items.filter(item => item.product.id === currentId && item.isGift === 0);
        quantity = totalItems.length;
        totalItems.forEach((item) => {
          const listItem = item;
          listItem.alreadyInList = true;
        });
      }

      return x.transferTaxes(quantity);
    }

    return 0;
  });

  transferTaxesArr = transferTaxesArr.filter(item => !!item && item > 0);
  return transferTaxesArr.reduce((acc, amount) => acc + amount, 0);
}

/**
 * @param {PurchaseOrderProductItem[]} items
 * @returns {T[]}
 */
export function purchaseOrderProductsToTicketData(items) {
  let ticketData = [];

  items.forEach((x) => {
    const item = x;
    item.alreadyInList = false;
  });

  ticketData = items.map((x) => {
    let quantity = 0;

    // if not in list, add... else, ignore
    if (!x.alreadyInList) {
      // eslint-disable-next-line object-curly-newline, max-len
      const { isGift, currentCost: baseCost, costDiscount: calculatedDiscount } = x;
      const { comboId, product } = x;
      const { id, name, model } = product;

      // check for same items in array
      // set quantity property for next operations
      if (isGift) {
        // eslint-disable-next-line max-len
        const giftItemsWithSameComboId = items.filter(item => item.comboId === comboId && item.product.id === id);
        quantity = giftItemsWithSameComboId.length;
        giftItemsWithSameComboId.forEach((item) => {
          const listItem = item;
          listItem.alreadyInList = true;
        });
      } else {
        const totalItems = items.filter(item => item.product.id === id && item.isGift === 0);
        quantity = totalItems.length;
        totalItems.forEach((item) => {
          const listItem = item;
          listItem.alreadyInList = true;
        });
      }

      const totalCost = baseCost * quantity;
      const costStr = CurrencyFormatter.format(totalCost);

      const totalDiscount = calculatedDiscount * quantity;
      const discountStr = totalDiscount > 0 ? CurrencyFormatter.format(totalDiscount) : '';

      const description = [];
      if (!!model) {
        description.push(model.slice(0, 30));
      }

      if (!!name) {
        description.push(name.slice(0, 30));
      }

      const unitPrice = CurrencyFormatter.format(baseCost);
      const total = costStr;
      const discount = discountStr;

      return {
        id,
        quantity,
        description,
        unitPrice,
        total,
        discount,
      };
    }
  });

  ticketData = ticketData.filter(item => !!item);

  return ticketData;
}

/**
 * @param {PurchaseOrderSingleItem} item
 * @returns object
 */
export function purchaseOrderToTicketData(item) {
  // eslint-disable-next-line object-curly-newline
  const { id, employeeName, clientName, totalAmountStr, createdDtStr } = item;
  const {
    cashAmountStr,
    creditCardAmountStr,
    debitCardAmountStr,
    checkAmountStr,
    transferAmountStr,
    changeAmountStr,
  } = item;

  const transferTaxes = transferTaxesAmountFromPurchaseOrder(item.products);

  const ticketProducts = purchaseOrderProductsToTicketData(item.products);
  const ivaAmountStr = CurrencyFormatter.format(transferTaxes);

  const
    data = {
      employee: employeeName,
      client: clientName,
      createdDt: createdDtStr,
      includePrintedDt: false,
      paymentData: {
        cashTotal: cashAmountStr,
        creditCardTotal: creditCardAmountStr,
        debitCardTotal: debitCardAmountStr,
        checkTotal: checkAmountStr,
        transferTotal: transferAmountStr,
        cashPaidTotal: cashAmountStr,
        changeTotal: changeAmountStr,
      },
      saleSummary: {
        total: totalAmountStr,
        iva: ivaAmountStr,
      },
      products: ticketProducts,
    };

  const ticketStruct = {
    header: {
      title: '',
      address: [
        'CARRETERA NACIONAL, PLAZA PALMARES NORTE  LOCAL #10, C.P. 64983',
        'NUEVO LEON, MEXICO',
        'CORREO americantastemty@gmail.com',
      ],
    },
    saleGeneral: {
      clientLabel: 'CLIENTE - ',
      employeeLabel: 'EMPLEADO(A) - ',
      createdDt: 'Fecha: ',
      printedDt: 'Impreso el ',
      discountLabel: 'Descuento del producto',
    },
    saleDetail: [
      'Ctd',
      'Descripcion',
      'P. Unit',
      'Importe',
    ],
    saleSummary: {
      ivaLabel: 'IVA INCLUIDO',
      totalLabel: 'TOTAL',
      cashPaidLabel: ' TOTAL EFECTIVO',
      changeLabel: 'CAMBIO',
      debitCardLabel: 'TARJ. DEBITO',
      creditCardLabel: 'TARJ. CREDITO',
      checkLabel: 'CHEQUE',
      transferLabel: 'TRANSFERENCIA',
    },
    footer: {
      farewellMsg: 'Gracias por tu compra!',
      additional: [],
      barcodeContent: id?.toString(),
    },
  };

  return {
    id,
    data,
    ticketStruct,
  };
}

export function makeFrontItemFromItem(item, quantity) {
  // eslint-disable-next-line
  const { id, name, isGift, model, position, brandName: brand } = item;
  const totalCost = item.baseCost * quantity;
  const costStr = CurrencyFormatter.format(totalCost);
  const totalDiscount = item.calculatedDiscount * quantity;
  const discountStr = CurrencyFormatter.format(totalDiscount);
  const costWithDiscount = totalCost - totalDiscount;
  const costWithDiscountStr = CurrencyFormatter.format(costWithDiscount);
  const baseCostStr = CurrencyFormatter.format(item.baseCost);

  return {
    id,
    name,
    model,
    brand,
    isGift,
    quantity,
    baseCostStr,
    costStr,
    discountStr,
    costWithDiscountStr,
    position,
    calculatedDiscount: totalDiscount,
  };
}
