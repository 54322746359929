import Vue from 'vue';
import router from './router';
import store from './store';
import App from './App.vue';

import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';
import './global.css';

// import plugins
import vuetify from './plugins/vuetify';
import './plugins/moment';
import './plugins/currency';
import './plugins/sweetalert';
import './plugins/snotify';
import './plugins/barcode-scanner';
import './plugins/currency-field';
import './plugins/resize-text';
import './plugins/gmaps';

Vue.config.productionTip = false;

Vue.filter('toCurrency', (value) => {
  if (typeof value !== 'number') {
    return value;
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return formatter.format(value);
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
