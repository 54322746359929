export const DeliveryOrderStatus = {
  UNDEFINED: -1,
  CANCELLED: 0,
  PENDING: 1,
  OUT_FOR_DELIVERY: 2,
  DELIVERED: 3,
};

export const DeliveryOrderStatusLabel = {
  [DeliveryOrderStatus.UNDEFINED]: 'Undefined',
  [DeliveryOrderStatus.CANCELLED]: 'Cancelled',
  [DeliveryOrderStatus.PENDING]: 'Pending Dispatch',
  [DeliveryOrderStatus.OUT_FOR_DELIVERY]: 'Dispatched',
  [DeliveryOrderStatus.DELIVERED]: 'Delivered',
};

export const DeliveryOrderStatusFilter = [
  {
    value: null,
    label: 'All',
  },
  {
    value: DeliveryOrderStatus.CANCELLED,
    label: DeliveryOrderStatusLabel[DeliveryOrderStatus.CANCELLED],
  },
  {
    value: DeliveryOrderStatus.PENDING,
    label: DeliveryOrderStatusLabel[DeliveryOrderStatus.PENDING],
  },
  {
    value: DeliveryOrderStatus.OUT_FOR_DELIVERY,
    label: DeliveryOrderStatusLabel[DeliveryOrderStatus.OUT_FOR_DELIVERY],
  },
  {
    value: DeliveryOrderStatus.DELIVERED,
    label: DeliveryOrderStatusLabel[DeliveryOrderStatus.DELIVERED],
  },
];

export class DeliveryOrder {
  constructor() {
    /** @type number */
    this.id = 0;

    /** @type string */
    this.createdAt = '';

    /** @type string */
    this.deliveryDate = '';

    /** @type DeliveryOrderPackage[] */
    this.packages = [];

    /** @type ?string */
    this.dispatchedAt = null;

    /** @type ?string */
    this.finishedAt = null;

    /** @type number */
    this.regStatus = DeliveryOrderStatus.UNDEFINED;
  }

  get regStatusLabel() {
    if (Object.keys(DeliveryOrderStatusLabel).includes(this.regStatus.toString())) {
      return DeliveryOrderStatusLabel[this.regStatus];
    }

    return 'Undefined';
  }

  get packagesCount() {
    return this.packages?.length ?? 0;
  }
}
