import Vue from 'vue';
import VueRouter from 'vue-router';
import { TokenUtils } from '@/utils/TokenUtils';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'root',
    component: () => {
      if (!TokenUtils.hasToken()) {
        return import('../views/Login.vue');
      }

      return null;
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/Dashboard.vue'),
  },
  {
    path: '/kits',
    name: 'kits',
    component: () => import('../views/Kits.vue'),
  },
  {
    path: '/new-kit',
    name: 'new-kits',
    component: () => import('../components/CRUD/CreateKit.vue'),
  },
  {
    path: '/kit-products',
    name: 'kit-products',
    component: () => import('../components/CRUD/KitDetail.vue'),
  },
  {
    path: '/new-product',
    name: 'new-product',
    component: () => import('../views/CreateProduct.vue'),
  },
  {
    path: '/:id/edit-product',
    name: 'edit-product',
    component: () => import('../views/EditProduct.vue'),
  },
  {
    path: '/specials-discount-home',
    name: 'specials-discount-home',
    component: () => import('../views/SpecialsHome.vue'),
  },
  {
    path: '/new-special/:type',
    name: 'new-special',
    component: () => import('../components/CRUD/Specials/CreateDiscountSpecial.vue'),
  },
  {
    path: '/:id/edit-discount-special',
    name: 'edit-discount-special',
    component: () => import('../components/CRUD/Specials/EditDiscountSpecial.vue'),
  },
  {
    path: '/specials-combo-home',
    name: 'specials-combo-home',
    component: () => import('../views/SpecialsComboHome.vue'),
  },
  {
    path: '/:id/edit-combo-special',
    name: 'combo-discount-special',
    component: () => import('../components/CRUD/Specials/EditComboSpecial.vue'),
  },
  {
    path: '/tags-home',
    name: 'tags-home',
    component: () => import('../views/TagsHome.vue'),
  },
  {
    path: '/brands-home',
    name: 'brands-home',
    component: () => import('../views/BrandsHome.vue'),
  },
  {
    path: '/suppliers-home',
    name: 'suppliers-home',
    component: () => import('../views/SuppliersHome.vue'),
  },
  {
    path: '/measuring-units-home',
    name: 'measuring-units-home',
    component: () => import('../views/MeasuringUnitsHome.vue'),
  },
  {
    path: '/purchase-orders',
    name: 'purchase-orders',
    component: () => import('../views/PurchaseOrders.vue'),
  },
  {
    path: '/purchase-order',
    name: 'purchase-order',
    component: () => import('../views/PurchaseOrder.vue'),
  },
  {
    path: '/merch-entry',
    name: 'merch-entry',
    component: () => import('../views/MerchEntry.vue'),
  },
  {
    path: '/stores-inventory',
    name: 'stores-inventory',
    component: () => import('../views/StoresInventory.vue'),
  },
  {
    path: '/sales-orders-admin',
    name: 'sales-orders-admin',
    component: () => import('../views/SalesOrdersAdmin.vue'),
  },
  {
    path: '/sales-orders-dispatches',
    name: 'sales-orders-dispatches',
    component: () => import('../views/SalesOrdersDispatches.vue'),
  },
  {
    path: '/delivery/client-orders',
    name: 'delivery-client-orders',
    component: () => import('../views/delivery/ClientsOrders.vue'),
  },
  {
    path: '/delivery/client-order/:id/packages',
    name: 'delivery-client-order-packages',
    component: () => import('../views/delivery/ClientOrderPackages.vue'),
  },
  {
    path: '/client-orders',
    name: 'Client Orders',
    component: () => import('../views/Client-orders.vue'),
  },
  {
    path: '/delivery/tracking',
    name: 'delivery-tracking',
    component: () => import('../views/delivery/DeliveryTracking.vue'),
  },
  {
    path: '/delivery-order/new',
    name: 'delivery-order-new',
    component: () => import('../views/delivery/DeliveryOrderNew.vue'),
  },
  {
    path: '/pos',
    name: 'pos',
    component: () => import('../views/POS.vue'),
  },
  {
    path: '/drivers-home',
    name: 'drivers-home',
    component: () => import('../views/DriversHome.vue'),
  },
  {
    path: '/driver-map',
    name: 'driver-map',
    component: () => import('../views/Google-maps.vue'),
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('../views/catalogs/UsersHome.vue'),
  },
  {
    path: '/banners',
    name: 'banners',
    component: () => import('../views/BannersHome.vue'),
  },
  {
    path: '/user/new',
    name: 'user-create',
    component: () => import('../views/catalogs/UserCreateForm.vue'),
  },
  {
    path: '/user/:id/edit',
    name: 'user-edit',
    component: () => import('../views/catalogs/UserEditForm.vue'),
  },
  {
    path: '/reports/sales',
    name: 'client-orders',
    component: () => import('../views/Sales.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
