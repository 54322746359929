export class MessageResponse {
  /**
   * @param {!string} message
   * @param {?number} code
   */
  constructor(message, code = null) {
    this.message = message;
    this.code = code;
  }
}
