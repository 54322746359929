import Axios from '@/services/Axios';
// import { ErrorResponse } from '../common/models/ErrorResponse';
import { SalesReportSingleItem } from '../common/models/SalesReportSingleItem';
import { CashCloseReportSingleItem } from '../common/models/CashCloseReportSingleItem';
import { SalesReportPaymentsData } from '../common/models/SalesReporPaymentsData';
import { ProductReportSingleItem } from '../common/models/ProductReportSingleItem';

const resource = '/report';

export default {
  async getSalesReportData(limit, page, initDate, endDate) {
    let resp = null;

    const data = await Axios.get(`${resource}/sales/data`, {
      params: {
        limit,
        page,
        initDate,
        endDate,
      },
    });
    resp = [];
    data.data.forEach((item) => {
      const salesReportItem = new SalesReportSingleItem(
        item.id,
        item.client,
        item.createdDt,
        item.isClosed,
        item.total,
        item.employee,
        item.cashPayments,
        item.debitCardPayments,
        item.creditCardPayments,
        item.checkPayments,
        item.transferPayments,
        item.realCashPayments,
        item.products,
      );
      resp.push(salesReportItem);
    });

    return resp;
  },
  async getSalesReportCount(initDate, endDate) {
    let resp = null;

    const data = await Axios.get(`${resource}/sales/count`, {
      params: {
        initDate,
        endDate,
      },
    });
    resp = data.data.total;
    return resp;
  },

  async getSalesReportPaymentsData(initDate, endDate) {
    let resp = null;
    const data = await Axios.get(`${resource}/sales/payments-data`, {
      params: {
        initDate,
        endDate,
      },
    });
    resp = new SalesReportPaymentsData(
      data.data.totalAmount,
      data.data.cashAmount,
      data.data.debitAmount,
      data.data.creditAmount,
      data.data.checkAmount,
      data.data.transferAmount,
    );
    return resp;
  },

  async downloadSalesReport(initDate, endDate) {
    const data = await Axios({
      method: 'get',
      url: `${resource}/sales/download`,
      responseType: 'blob',
      params: {
        initDate,
        endDate,
      },
    });
    const startDt = initDate.split(' ')[0];
    const endDt = endDate.split(' ')[0];
    const filename = `reporte-ventas-${startDt}-${endDt}.csv`;
    this.forceFileDownload(data, filename);
  },
  async getCashCloseReportData(limit, page, initDate, endDate) {
    let resp = null;

    const data = await Axios.get(`${resource}/cash-close/data`, {
      params: {
        limit,
        page,
        initDate,
        endDate,
      },
    });
    resp = [];
    data.data.forEach((item) => {
      const newItem = new CashCloseReportSingleItem(
        item.createdDt,
        item.amount,
        item.cashStartAmount,
        item.totalOrders,
        item.orders,
      );
      resp.push(newItem);
    });

    return resp;
  },
  async getCashCloseReportCount(initDate, endDate) {
    let resp = null;

    const data = await Axios.get(`${resource}/cash-close/count`, {
      params: {
        initDate,
        endDate,
      },
    });
    resp = data.data.total;
    return resp;
  },
  async getProductReportData(limit, page, initDate, endDate) {
    let resp = null;

    const data = await Axios.get(`${resource}/product/data`, {
      params: {
        limit,
        page,
        initDate,
        endDate,
      },
    });
    resp = [];
    data.data.forEach((item) => {
      const newItem = new ProductReportSingleItem(
        item.productId,
        item.totalSold,
        item.name,
        item.keys,
        item.model,
        item.totalAmount,
      );
      resp.push(newItem);
    });

    return resp;
  },
  async getProductReportCount(initDate, endDate) {
    let resp = null;

    const data = await Axios.get(`${resource}/product/count`, {
      params: {
        initDate,
        endDate,
      },
    });
    resp = data.data.total;
    return resp;
  },

  async downloadCashCloseReport(initDate, endDate, limit, page) {
    const data = await Axios({
      method: 'get',
      url: `${resource}/cash-close/download`,
      responseType: 'blob',
      params: {
        initDate,
        endDate,
        limit,
        page,
      },
    });
    const startDt = initDate.split(' ')[0];
    const endDt = endDate.split(' ')[0];
    const filename = `reporte-corte-${startDt}-${endDt}.csv`;
    this.forceFileDownload(data, filename);
  },
  async downloadProductReport(initDate, endDate) {
    const data = await Axios({
      method: 'get',
      url: `${resource}/product/download`,
      responseType: 'blob',
      params: {
        initDate,
        endDate,
      },
    });
    const startDt = initDate.split(' ')[0];
    const endDt = endDate.split(' ')[0];
    const filename = `reporte-productos-${startDt}-${endDt}.csv`;
    this.forceFileDownload(data, filename);
  },
  forceFileDownload(response, filename) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename); // or any other extension
    document.body.appendChild(link);
    link.click();
  },
};
