import { makeCartItemFromProductListItem, makeClientOrderPosRequestDataFromArgs } from '@/serializers/Pos.serializer';
import PosService from '@/services/PosService';
import { PM_TYPES } from '@/models/pos/PosPayment';

export const types = {
  CLEAR_POS_CART_ITEMS: 'CLEAR_POS_CART_ITEMS',
  CLEAR_POS_CART_PREVIEW_ITEM: 'CLEAR_POS_CART_PREVIEW_ITEM',
  UPDATE_POS_CLIENTS: 'UPDATE_POS_CLIENTS',
  UPDATE_POS_PRODUCTS: 'UPDATE_POS_PRODUCTS',
  UPDATE_POS_SELECTED_CLIENT: 'UPDATE_POS_SELECTED_CLIENT',
  APPEND_ITEM_TO_POS_CART: 'APPEND_ITEM_TO_POS_CART',
  UPDATE_QUANTITY_IN_POS_CART_ITEM: 'UPDATE_QUANTITY_IN_POS_CART_ITEM',
  UPDATE_PRICE_IN_POS_CART_ITEM: 'UPDATE_PRICE_IN_POS_CART_ITEM',
  UPDATE_POS_CART_PREVIEW_ITEM: 'UPDATE_POS_CART_PREVIEW_ITEM',
  DELETE_ITEM_FROM_POS_CART: 'DELETE_ITEM_FROM_POS_CART',
  APPEND_ITEM_TO_POS_PAYMENTS: 'APPEND_ITEM_TO_POS_PAYMENTS',
  CLEAR_POS_PAYMENTS: 'CLEAR_POS_PAYMENTS',
  SET_TAXES_COST: 'SET_TAXES_COST',
  SET_SHIPPING_COST: 'SET_SHIPPING_COST',
  SET_POS_ADDRESS: 'SET_POS_ADDRESS',
};

const actions = {
  clearPosData({ commit }) {
    commit(types.CLEAR_POS_CART_ITEMS);
    commit(types.CLEAR_POS_CART_PREVIEW_ITEM);
    commit(types.CLEAR_POS_PAYMENTS);
    commit(types.SET_TAXES_COST, 0);
    commit(types.SET_SHIPPING_COST, 0);
  },

  clearPosCart({ commit }) {
    commit(types.CLEAR_POS_CART_ITEMS);
  },

  setPosProducts({ commit }, payload) {
    commit(types.UPDATE_POS_PRODUCTS, payload);
  },

  setPosClients({ commit }, payload) {
    commit(types.UPDATE_POS_CLIENTS, payload);
  },

  setPosSelectedClient({ commit }, payload) {
    commit(types.UPDATE_POS_SELECTED_CLIENT, payload);
  },

  addProductToCart(
    {
      commit,
      state,
      dispatch,
    },
    payload,
  ) {
    /** @type {CartItem} */
    const existentItem = state.posCartItems.find(item => item.id === payload.id);

    if (!existentItem) {
      // create cart item and append to cart
      const cartItem = makeCartItemFromProductListItem(payload);
      commit(types.APPEND_ITEM_TO_POS_CART, cartItem);
    } else {
      // update cart item quantity, increase quantity by 1
      const idxItemInCart = state.posCartItems.findIndex(item => item.id === payload.id);
      commit(types.UPDATE_QUANTITY_IN_POS_CART_ITEM, {
        idxItemInCart,
        newQuantity: existentItem.quantity + 1,
      });
    }

    dispatch('calculateSpecials');
  },

  setPosCartPreviewItem({ commit }, payload) {
    commit(types.UPDATE_POS_CART_PREVIEW_ITEM, payload);
  },

  deleteProductFromCart(
    {
      commit,
      state,
      dispatch,
    },
    payload,
  ) {
    const idxItemInCart = state.posCartItems.findIndex(item => item.id === payload.id);
    const clearCartPreviewItem = !!state.posCartPreviewItem && state.posCartPreviewItem.id === payload.id;

    commit(types.DELETE_ITEM_FROM_POS_CART, idxItemInCart);
    if (clearCartPreviewItem) {
      commit(types.CLEAR_POS_CART_PREVIEW_ITEM);
    }

    dispatch('calculateSpecials');
  },

  setCartItemQuantity(
    {
      commit,
      state,
    },
    payload,
  ) {
    const {
      productId,
      newQuantity,
    } = payload;

    const idxItemInCart = state.posCartItems.findIndex(item => item.id === productId);
    commit(types.UPDATE_QUANTITY_IN_POS_CART_ITEM, {
      idxItemInCart,
      newQuantity,
    });
  },

  setCartItemPrice(
    {
      commit,
      state,
    },
    payload,
  ) {
    const {
      productId,
      newValue,
    } = payload;

    const idxItemInCart = state.posCartItems.findIndex(item => item.id === productId);
    commit(types.UPDATE_PRICE_IN_POS_CART_ITEM, {
      idxItemInCart,
      newValue,
    });
  },

  async addPosPayment({ commit }, payload) {
    commit(types.APPEND_ITEM_TO_POS_PAYMENTS, payload);
  },

  async clearPosPayments({ commit }) {
    commit(types.CLEAR_POS_PAYMENTS);
  },

  async savePosClientOrder({ state }) {
    const subtotal = state.posCartItems.reduce((acc, item) => acc + item.total, 0);
    const requestData = makeClientOrderPosRequestDataFromArgs(
      state.posSelectedClient.id,
      subtotal,
      state.taxesCost,
      state.shippingCost,
      state.posCartItems,
      state.posPayments,
      state.posAddress,
    );
    return PosService.saveClientOrder(requestData);
  },

  async calculateSpecials() {
    console.log('todo: calculateSpecials');
  },

  async setTaxesCost({ commit }, payload) {
    commit(types.SET_TAXES_COST, payload);
  },

  async setShippingCost({ commit }, payload) {
    commit(types.SET_SHIPPING_COST, payload);
  },

  setPosAddress({ commit }, payload) {
    commit(types.SET_POS_ADDRESS, payload);
  },
};

const mutations = {
  [types.CLEAR_POS_CART_ITEMS](state) {
    state.posCartItems = [];
  },

  [types.CLEAR_POS_CART_PREVIEW_ITEM](state) {
    state.posCartPreviewItem = null;
  },

  [types.UPDATE_POS_PRODUCTS](state, products) {
    state.posProducts = products;
  },

  [types.UPDATE_POS_CLIENTS](state, clients) {
    state.posClients = clients;
  },

  [types.UPDATE_POS_SELECTED_CLIENT](state, payload) {
    state.posSelectedClient = payload;
  },

  [types.APPEND_ITEM_TO_POS_CART](state, payload) {
    state.posCartItems = [...state.posCartItems, payload];
  },

  [types.UPDATE_QUANTITY_IN_POS_CART_ITEM](
    state,
    {
      idxItemInCart,
      newQuantity,
    },
  ) {
    const editItem = state.posCartItems[idxItemInCart];
    editItem.quantity = newQuantity;

    state.posCartItems = [...state.posCartItems];
  },

  [types.UPDATE_PRICE_IN_POS_CART_ITEM](
    state,
    {
      idxItemInCart,
      newValue,
    },
  ) {
    const editItem = state.posCartItems[idxItemInCart];
    editItem.baseCost = newValue;

    state.posCartItems = [...state.posCartItems];
  },

  [types.UPDATE_POS_CART_PREVIEW_ITEM](state, payload) {
    state.posCartPreviewItem = payload;
  },

  [types.DELETE_ITEM_FROM_POS_CART](state, payload) {
    const tmpItems = [...state.posCartItems];
    tmpItems.splice(payload, 1);

    state.posCartItems = [...tmpItems];
  },

  [types.APPEND_ITEM_TO_POS_PAYMENTS](state, payload) {
    state.posPayments = [...state.posPayments, payload];
  },

  [types.CLEAR_POS_PAYMENTS](state) {
    state.posPayments = [];
  },

  [types.SET_TAXES_COST](state, payload) {
    state.taxesCost = payload;
  },

  [types.SET_SHIPPING_COST](state, payload) {
    state.shippingCost = payload;
  },

  [types.SET_POS_ADDRESS](state, payload) {
    state.posAddress = payload;
  },
};

const getters = {
  posAddress: state => state.posAddress,
  posCartItems: state => state.posCartItems,
  posCartItemsLength: state => state.posCartItems.length,
  posClients: state => state.posClients,
  posProducts: state => state.posProducts,
  posCartPreviewItem: state => state.posCartPreviewItem,
  posCartPreviewItemId: state => state.posCartPreviewItem?.id ?? 0,
  posCartPreviewItemName: state => state.posCartPreviewItem?.name,
  posCartTotal: state => state.posCartItems.reduce((acc, item) => acc + item.total, 0) + state.taxesCost + state.shippingCost,

  // eslint-disable-next-line max-len
  posPaymentsTotal: state => (state.posPayments ? state.posPayments.reduce((acc, item) => acc + item.paymentAmount, 0) : 0),
  posPaymentsLength: state => state.posPayments?.length ?? 0,

  // eslint-disable-next-line no-shadow
  posPaymentsRemaining: (state, getters) => {
    const remaining = getters.posCartTotal - getters.posPaymentsTotal;
    return remaining >= 0 ? remaining : 0;
  },

  posPaymentsCashTotal: (state) => {
    const cashPayments = state.posPayments.filter(item => item.paymentMethod === PM_TYPES.CASH);
    return cashPayments.reduce((acc, item) => acc + item.paymentAmount, 0);
  },

  posSelectedClientId: state => state.posSelectedClient?.id ?? 0,
};

export default {
  actions,
  mutations,
  getters,

  state: {
    posAddress: null,

    posCartItems: [],
    posCartPreviewItem: null,

    taxesCost: 0,
    shippingCost: 0,
    posClients: [],
    posProducts: [],
    posPayments: [],

    posSelectedClient: null,
  },
};
