import CurrencyFormatter from '@/models/CurrencyFormatter';

export class ProductListItem {
  constructor(
    id,
    name,
    barcode,
    baseCost,
    images,
    model,
    measuringUnit,
    brand,
    realPrice,
    discountRate,
  ) {
    this.id = id;
    this.name = name;
    this.barcode = barcode;

    /** @type float */
    this.baseCost = baseCost;

    this.image = images ? images[0] : null;
    this.model = model;

    this.measuringUnit = !measuringUnit ? 'No unit of measurement' : measuringUnit.label;

    this.brand = brand;

    this.realPrice = realPrice;
    this.discountRate = discountRate;
  }

  get autocompleteLabel() {
    return `${this.model} - ${this.name} - ${this.measuringUnit}`;
  }

  get imageUrl() {
    if (!this.image) {
      // todo: set own placeholder image
      return 'https://via.placeholder.com/250';
    }

    return this.image.fileUrl;
  }

  get baseCostStr() {
    return CurrencyFormatter.format(this.baseCost);
  }

  get brandName() {
    return this.brand?.name ?? 'No brand';
  }
}
