import { ErrorResponse } from '@/common/models/ErrorResponse';
import { MessageResponse } from '@/models/MessageResponse';
import { CountResponse } from '@/models/CountResponse';

export function makeErrorResponseFromData(data, headers, status) {
  // if not object, return same data to next transform function
  if (typeof data !== 'object') {
    return data;
  }

  const inst = new ErrorResponse(data);
  inst.httpStatus = status;

  throw inst;
}

export function makeMessageResponseFromData(data) {
  const { message } = data;

  const code = data.code || null;

  return new MessageResponse(message, code);
}

export function makeCountResponseFromData(data) {
  const { count } = data;

  return new CountResponse(count);
}

export function messageResponseSerializer(data, headers, status) {
  // if not object, return same data to next transform function
  if (typeof data !== 'object') {
    return data;
  }

  // process error
  if (status >= 400 && status < 500) {
    return makeErrorResponseFromData(data, headers, status);
  }

  return makeMessageResponseFromData(data);
}

export function countResponseSerializer(data, headers, status) {
  // if not object, return same data to next transform function
  if (typeof data !== 'object') {
    return data;
  }

  // process error
  if (status >= 400 && status < 500) {
    return makeErrorResponseFromData(data, headers, status);
  }

  return makeCountResponseFromData(data);
}
