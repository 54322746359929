import Axios, { setAuthHeaders } from '@/services/Axios';
import { ErrorResponse } from '@/common/models/ErrorResponse';
import { PurchaseOrderRequest } from '@/models/PurchaseOrder/PurchaseOrderRequest';

const resource = '/supplier-purchase-order';

export default {
  async createPurchaseOrder(products) {
    const payload = new PurchaseOrderRequest(products);
    const headers = setAuthHeaders({});

    try {
      return await Axios.post(`${resource}`, payload, { headers });
    } catch (e) {
      console.error(e.response);
      throw new ErrorResponse(e.response.data);
    }
  },

  async getPurchaseOrderTable(limit, offset, supplierId, date, status) {
    const params = {
      limit,
      offset,
      date,
      status,
      supplier: supplierId,
    };
    const headers = setAuthHeaders({});

    try {
      const { data: response } = await Axios.get(`${resource}/table`, {
        headers,
        params,
      });
      return response;
    } catch (e) {
      console.error(e.response);
      return new ErrorResponse(e.response.data);
    }
  },

  async finishPurchaseOrders(id, products) {
    const params = {
      products,
    };
    const headers = setAuthHeaders({});

    try {
      return await Axios.post(`${resource}/${id}/finish`, params, { headers });
    } catch (e) {
      console.error(e.response);
      throw new ErrorResponse(e.response.data);
    }
  },

  async cancelPurchaseOrders(id) {
    const headers = setAuthHeaders({});

    try {
      return await Axios.post(`${resource}/${id}/cancel`, null, { headers });
    } catch (e) {
      console.error(e);
      return new ErrorResponse(e.response.data);
    }
  },
};
