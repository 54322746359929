/**
 * @param {number} ms
 * @return {Promise<any>}
 */
export const delay = ms => new Promise((_) => {
  setTimeout(_, ms);
});

export function readImageAsDataUrl(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const { result } = e.target;
      if (result) {
        resolve(result);
      }
    };

    reader.onabort = () => reject(new Error('File reading aborted'));
    reader.onerror = () => reject(new Error('Failed to read file'));

    reader.readAsDataURL(file);
  });
}

export function redirectToUserSectionByAttributes($router, attributes) {
  const adminViews = attributes.filter(item => !!item && item.startsWith('ADMIN_'));
  let path = null;

  if (adminViews.includes('ADMIN_PRODUCTS')) {
    path = '/dashboard';
  } else if (adminViews.includes('ADMIN_EMPLOYEES')) {
    path = '/employees-home';
  } else if (adminViews.includes('ADMIN_CLIENTS')) {
    path = '/clients-home';
  } else if (adminViews.includes('ADMIN_SALES')) {
    path = '/sales';
  } else if (adminViews.includes('ADMIN_SALES_ORDERS')) {
    path = '/sales-orders';
  } else if (adminViews.includes('ADMIN_PURCHASE_ORDERS')) {
    path = '/quotations';
  } else if (adminViews.includes('ADMIN_KITS')) {
    path = '/kits';
  } else if (adminViews.includes('ADMIN_CATALOGS')) {
    path = '/tags-home';
  } else if (adminViews.includes('ADMIN_PROMOTIONS')) {
    path = '/specials-discount-home';
  } else if (adminViews.includes('ADMIN_INVENTORY')) {
    path = '/stores-inventory';
  } else if (adminViews.includes('ADMIN_SALES_ORDERS_ADMIN')) {
    path = '/dashboard';
  } else if (adminViews.includes('ADMIN_DRIVER_MAP')) {
    path = '/drivers-home';
  }

  if (path) {
    $router.push(path);
  }
}

/**
 * @param {any} context
 * @param {Object} options
 * @returns {Promise<SweetAlertResult>}
 */
export async function showAlert(context, options) {
  if (!context.$swal || !options) {
    return Promise.reject('Undefined');
  }

  return context.$swal(options);
}

export function cleanPhoneNumber(value) {
  const v = !value ? '' : value.toString();

  if (!v) {
    return '';
  }

  return value.replace(/\D/g, '');
}

export function formatPhoneNumber(value) {
  const v = cleanPhoneNumber(value);

  // if invalid value, return same value
  if (v.length !== 10) {
    return v;
  }

  const pattern = '(###) ###-####';
  let i = 0;

  // eslint-disable-next-line no-plusplus,no-unused-vars
  return pattern.replace(/#/g, _ => v[i++]);
}

export function validateEmail(email) {
  const emailRegexp = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
  return emailRegexp.test(email);
}

export function validatePhoneNumber(phoneNumber) {
  const phoneRegexp = /^\(\d{3}\)\s\d{3}-\d{4}$/;
  return phoneRegexp.test(phoneNumber);
}
