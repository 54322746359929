const actions = {
  showSalesOrderPendingTable({ commit }) {
    commit('SET_CREATE_STEP', 1);
  },
  showSalesOrderOutputRequest({ commit }) {
    commit('SET_CREATE_STEP', 2);
  },
  selectSalesOrder({ commit }, payload) {
    let modPayload = payload;

    if (payload) {
      modPayload = JSON.parse(JSON.stringify(payload));

      modPayload.products = modPayload.products.map((item) => {
        const newItem = JSON.parse(JSON.stringify(item));
        newItem.selected = false;
        return newItem;
      });
    }

    commit('SET_SELECTED_SALES_ORDER', modPayload);
  },

  backFromPurchaseOrderClosed({ commit }) {
    commit('SET_CREATE_STEP', 1);
    commit('SET_SELECTED_SALES_ORDER', null);
    commit('SET_FORCE_REFRESH_TABLE', true);
  },

  resetForceRefreshTable({ commit }) {
    commit('SET_FORCE_REFRESH_TABLE', false);
  },
};

const mutations = {
  SET_CREATE_STEP(state, value) {
    state.createStep = value;
  },
  SET_SELECTED_SALES_ORDER(state, value) {
    state.selectedSalesOrder = value;
  },
  SET_FORCE_REFRESH_TABLE(state, value) {
    state.forceRefreshTable = value;
  },
};

const getters = {
  createStep: state => state.createStep,
  selectedSalesOrder: state => state.selectedSalesOrder,
  forceRefreshTable: state => state.forceRefreshTable,
};

export default {
  actions,
  mutations,
  getters,

  state: {
    createStep: 1,
    selectedSalesOrder: null,
    forceRefreshTable: false,
  },
};
