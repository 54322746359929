export const PaymentType = {
  BRAINTREE: 1,
  CASH: 2,
  CARD: 3,
  CHECK_ASH: 4,
  TRANSFER: 5,
};

export const PaymentTypeLabel = {
  [PaymentType.BRAINTREE]: 'Braintree',
  [PaymentType.CASH]: 'Cash',
  [PaymentType.CARD]: 'Card',
  [PaymentType.CHECK_ASH]: 'Check / ACH',
  [PaymentType.TRANSFER]: 'Transfer',
};

export class ClientOrderPayment {
  constructor() {
    this.id = 0;
    this.type = '';
    this.code = '';
    this.total = 0;
  }

  get typeLabel() {
    if (Object.keys(PaymentTypeLabel).includes(this.type.toString())) {
      return PaymentTypeLabel[this.type];
    }

    return 'Payment type not defined';
  }
}
