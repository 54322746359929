export class FPUtils {
  static round2dec(value) {
    let roundedValue = value.toFixed(2);
    roundedValue = parseFloat(roundedValue);
    return roundedValue;
  }

  static roundDec(value, decimals) {
    let roundedValue = value.toFixed(decimals);
    roundedValue = parseFloat(roundedValue);
    return roundedValue;
  }

  static getItemCostWithDiscount(baseCost, discount) {
    const itemDiscount = this.getItemDiscount(baseCost, discount);
    const itemCostWithDiscount = FPUtils.round2dec(baseCost - itemDiscount);
    return itemCostWithDiscount;
  }

  static getItemDiscount(baseCost, discount) {
    const itemDiscountPercentage = FPUtils.roundDec(discount / 100, 4);
    const itemDiscount = baseCost * itemDiscountPercentage;
    return itemDiscount;
  }
}
