import Vue from 'vue';
import VueLodash from 'vue-lodash';
import lodash from 'lodash';
import Vuex from 'vuex';
import { makeFrontItemFromItem } from '@/serializers/PurchaseOrder.serializer';
import { ComboListItem } from '@/models/ComboListItem';
import { FPUtils } from '@/utils/FloatingPointUtils';

import pos from '@/store/pos';
import salesOrders from '@/store/sales-orders';
import delivery from '@/store/delivery';

import CurrencyFormatter from '../models/CurrencyFormatter';
import PurchaseOrderService from '../services/PurchaseOrderService';
import ReportService from '../services/ReportService';

Vue.use(Vuex);
Vue.use(VueLodash, { lodash });

/**
 * @typedef ComboStruct
 * @type {object}
 * @property {string} id
 * @property {number} comboId
 * @property {number} buyId
 * @property {number} buyAmount
 * @property {number} giftId
 * @property {number} giftAmount
 */

/**
 * @typedef ComboItemStruct
 * @type {object}
 * @property {number} index
 * @property {number} itemId
 * @property {number} comboId
 * @property {boolean} isGift
 * @property {number} completedComboId
 */

/**
 * @typedef ItemListStruct
 * @type {object}
 * @property {number} comboId
 * @property {number} isGift
 * @property {boolean} alreadyInList
 */

/**
 * @param items
 * @returns {ComboStruct[]}
 */
function getCombosFromItems(items) {
  // calculate combos array
  // eslint-disable-next-line no-unused-vars
  let combos = items.map((item, idx) => {
    if (!!item.comboSpecials) {
      return item.comboSpecials.map(item2 => ({
        id: `${item.id}-${item2.buyAmount}-${item2.giftProduct.id}-${item2.giftAmount}`,
        comboId: item2.id,
        buyId: item.id,
        buyAmount: item2.buyAmount,
        giftId: item2.giftProduct.id,
        giftAmount: item2.giftAmount,
      }));
    }

    return [];
  });

  // concat all combos arrays into one-level array
  combos = [].concat(...combos);

  // return unique-combos array
  const combosMap = new Map();
  combos.forEach(item => combosMap.set(item.id, item));

  return [...combosMap.values()];
}

/**
 * @param {ComboStruct} combo
 * @param {ComboItemStruct[]} items
 * @param {any[]} appendItems
 * @returns {[]}
 */
function extractCombosFromItems(combo, items, appendItems) {
  /** @var {ComboItemStruct[]} tempItems */
  const tempItems = Array.from(items);

  // eslint-disable-next-line
  const { buyId, buyAmount, giftId, giftAmount } = combo;
  let completedCombo = null;

  // eslint-disable-next-line
  const itemsToBuy = tempItems
    .filter(ti => ti.itemId === buyId)
    .slice(0, buyAmount);
  // eslint-disable-next-line
  const itemsToGift = tempItems
    .filter(ti => ti.itemId === giftId && itemsToBuy.indexOf(ti) === -1)
    .slice(0, giftAmount);

  if (itemsToBuy.length === buyAmount && itemsToGift.length === giftAmount) {
    itemsToGift.forEach((item) => {
      // noinspection UnnecessaryLocalVariableJS
      const item2 = item;
      item2.isGift = true;
    });
    completedCombo = {
      combo,
      items: [...itemsToBuy, ...itemsToGift],
    };

    // remove items from tempItems
    itemsToBuy.forEach(item => tempItems.splice(tempItems.indexOf(item), 1));
    itemsToGift.forEach(item => tempItems.splice(tempItems.indexOf(item), 1));
  }

  // if not completed combo, return appendItems
  if (!completedCombo) {
    return appendItems;
  }

  // append completed combo to items
  appendItems.push(completedCombo);

  // if can complete more items, recurse function
  const pendingItems = tempItems.filter(
    ({ itemId }) => itemId === buyId || itemId === giftId,
  );
  if (pendingItems.length > 0) {
    return extractCombosFromItems(combo, tempItems, appendItems);
  }

  // else, return appended items
  return appendItems;
}

/**
 * @param {ComboItemStruct} item
 * @param {number} comboId
 */
function setComboId(item, comboId) {
  // noinspection UnnecessaryLocalVariableJS
  const item2 = item;
  item2.comboId = comboId;
}

/**
 * @param {ComboItemStruct} item
 * @param {number} completedComboId
 */
function setCompletedComboId(item, completedComboId) {
  // noinspection UnnecessaryLocalVariableJS
  const item2 = item;
  item2.completedComboId = completedComboId;
}

/**
 * @param {ItemListStruct} item
 */
function resetComboItems(item) {
  // noinspection UnnecessaryLocalVariableJS
  const item2 = item;
  item2.comboId = 0;
  item2.isGift = 0;
}

/**
 * @param {ItemListStruct} item
 */
function setAlreadyInList(item) {
  const item2 = item;
  item2.alreadyInList = true;
}

/**
 * @param {ItemListStruct} item
 */
function clearAlreadyInList(item) {
  const item2 = item;
  item2.alreadyInList = false;
}

export default new Vuex.Store({
  state: {
    attributes: [],
    productsList: [],
    itemsList: [],
    itemsListKit: [],
    clientsList: [],
    itemToPreview: null,
    selectedClient: null,
    authUserData: null,
    cashCloseData: null,
    combosList: [],
    orderDetails: null,
    orderDetailsModal: false,
    totalOrderPaid: 0,
    frontItemsList: null,
    savedQuotationPurchaseOrderId: null,
    savedQuotationsAutocomplete: [],
    websocketAddress: null,
    productsSearchType: null,
    salesReportTableData: [],
    salesReportTableCount: 0,
    salesReportDetailsModal: false,
    salesReportDetailsData: null,
    salesReportFilters: {},
    cashCloseReportTableData: [],
    cashCloseReportTableCount: 0,
    cashCloseReportFilters: {},
    cashCloseReportDetailsModal: false,
    cashCloseReportDetailsData: null,
    productReportTableData: [],
    productReportTableCount: 0,
    productReportDetailsModal: false,
    productReportDetailsData: null,
    productReportFilters: {},
  },
  mutations: {
    SET_ATTRIBUTES(state, data) {
      state.attributes = data;
    },
    SET_PRODUCTS_AUTOCOMPLETE(state, payload) {
      state.productsList = payload;
    },
    SET_CLIENTS_AUTOCOMPLETE(state, payload) {
      state.clientsList = payload;
    },
    REMOVE_ITEM_FROM_FRONT_LIST(state, payload) {
      const newItemsList = state.itemsList.filter(
        x => x.id !== payload.id && x.isGift !== 1,
      );
      state.itemsList = newItemsList;
    },
    REMOVE_ITEM_KIT_FROM_FRONT_LIST(state, payload) {
      const newItemsList = state.itemsListKit.filter(
        x => x.id !== payload.id && x.isGift !== 1,
      );
      state.itemsListKit = newItemsList;
    },
    REMOVE_ITEM_FROM_LIST(state, payload) {
      const listItem = state.itemsList[payload];
      // Antes de eliminar un elemento del carrito, hay que ver si el item pertenece a un combo
      // ya que al quitarlo del carrito, el combo pierde validez
      // Si el item tiene un comboId, significa que pertence a un combo
      if (listItem.comboId) {
        // Buscamos el combo y si existe, se busca mediante el giftProductId del combo
        // todos los productos que tengan el mismo comboId y cuyo id sea igual al giftProductId
        // Esto es: en el carrito, obtener todos los items que nos regalan con el combo del producto
        // a elimnar.
        const combo = state.combosList.find(
          x => x.comboId === listItem.comboId,
        );
        if (combo) {
          const giftProducts = state.itemsList.filter(
            x => x.comboId === combo.comboId && x.id === combo.giftProductId,
          );

          // Por cada producto regalado que encontramos sacamos su indice, lo eliminamos del carrito
          // y en la lista de combos se resta una unidad a los productos regalados de ese combo
          giftProducts.forEach((item) => {
            const index = state.itemsList.indexOf(item);
            state.itemsList.splice(index, 1);
            combo.giftTotal -= 1;
          });
          // Tambien se resta un producto comprado al combo, ya que se quitará del carrito y
          // para que el combo no se quede en estatus de "completado"
          combo.boughtTotal -= 1;
        }
      }
      state.itemsList.splice(payload, 1);
    },
    ADD_ITEM_TO_LIST(state, payload) {
      const listItem = payload;
      state.itemsList.push(listItem);
    },
    ADD_ITEM_TO_LIST_KIT(state, payload) {
      const newItems = [...state.itemsListKit];
      const filteredItems = newItems.filter(obj => obj.id === payload.id);

      if (!!filteredItems && filteredItems.length) {
        const newItem = payload;
        const itemInList = filteredItems[0];
        const itemInListIdx = newItems.indexOf(itemInList);

        // modify quantity of item + 1
        newItem.position = itemInList.position;
        newItem.quantity = itemInList.quantity + 1;
        newItems[itemInListIdx] = newItem;

        // replace in array
        state.itemsListKit = newItems;
      } else {
        // append new item
        const newItem = payload;
        state.itemsListKit.push(newItem);
      }
    },
    CLEAR_ITEMS_TO_LIST_KIT(state) {
      state.itemsListKit = [];
    },
    PREVIEW_ITEM_INFO(state, payload) {
      state.itemToPreview = payload;
    },
    CLEAR_ITEMS_LIST(state) {
      state.itemsList = [];
    },
    CLEAR_ITEM_TO_PREVIEW(state) {
      state.itemToPreview = null;
    },
    SELECT_CLIENT(state, payload) {
      state.selectedClient = payload;
    },
    SET_AUTH_USER_DATA(state, payload) {
      state.authUserData = payload;
    },
    CLEAR_ORDER(state) {
      state.itemToPreview = null;
      state.itemsList = [];
      state.selectedClient = null;
    },
    SET_CASH_CLOSE_DATA(state, payload) {
      state.cashCloseData = payload;
    },
    SHOW_ORDER_DETAILS(state, payload) {
      state.orderDetails = payload;
      state.orderDetailsModal = true;
    },
    CLOSE_MODAL(state) {
      state.orderDetailsModal = false;
    },
    UPDATE_TOTAL_ORDER_PAID(state, payload) {
      state.totalOrderPaid += payload;
    },
    CLEAR_TOTAL_ORDER_PAID(state) {
      state.totalOrderPaid = 0;
    },
    REMOVE_FIXED_NUMBER_ITEMS_FROM_LIST(state, payload) {
      for (let i = 0; i < payload.quantity; i += 1) {
        for (let j = state.itemsList.length - 1; j >= 0; j -= 1) {
          if (
            state.itemsList[j].id === payload.id
            && state.itemsList[j].isGift !== 1
          ) {
            state.itemsList.splice(j, 1);
            break;
          }
        }
      }
      const newItemsList = state.itemsList.filter(x => x.isGift !== 1);
      state.itemsList = newItemsList;
    },
    MODIFY_PRICE(state, payload) {
      for (let i = 0; i <= state.itemsList.length; i += 1) {
        if (state.itemsList[i].id === payload.id) {
          state.itemsList[i].baseCost = payload.price;
          break;
        }
      }
    },
    MODIFY_KIT_QUANTITY(state, payload) {
      for (let i = 0; i <= state.itemsListKit.length; i += 1) {
        if (state.itemsListKit[i].id === payload.id) {
          state.itemsListKit[i].quantity = payload.value;
          break;
        }
      }
    },
    MODIFY_KIT_POSITION(state, payload) {
      for (let i = 0; i <= state.itemsListKit.length; i += 1) {
        if (state.itemsListKit[i].id === payload.id) {
          state.itemsListKit[i].position = payload.value;
          break;
        }
      }
    },
    CALCULATE_COMBOS(state) {
      state.itemsList.forEach(resetComboItems);

      const combos = getCombosFromItems(state.itemsList);
      let items = state.itemsList.map((item, index) => ({
        index,
        itemId: item.id,
        comboId: 0,
        isGift: false,
        completedComboId: null,
      }));

      let combosCount = 1;

      combos.forEach((combo) => {
        const unattachedItems = items.filter(({ comboId }) => comboId === 0);
        let completedCombos = extractCombosFromItems(
          combo,
          unattachedItems,
          [],
        );
        completedCombos = completedCombos.filter(filterItem => !!filterItem);

        // has completed combos, set comboId to items
        if (completedCombos.length > 0) {
          // set completed combo id to items
          completedCombos.forEach((item) => {
            item.items.forEach(item2 => setCompletedComboId(item2, combosCount));
            combosCount += 1;
          });

          const completedCombosItems = completedCombos.flatMap(item => [
            ...item.items,
          ]);

          // must modify items by object reference in array
          completedCombosItems.forEach(item => setComboId(item, combo.comboId));
        }
      });

      // remove unnecessary items
      items = items.filter(item => !!item.completedComboId);

      let completedComboIds = new Set(
        items.map(item => item.completedComboId),
      );
      completedComboIds = Array.from(completedComboIds);

      // set combosList from items
      state.combosList = completedComboIds.map((comboId) => {
        const comboItems = items.filter(
          item => item.completedComboId === comboId,
        );

        const buyItems = comboItems.filter(item => !item.isGift);
        const giftItems = comboItems.filter(item => item.isGift);
        const { itemId: buyItemId } = buyItems[0];
        const { itemId: giftItemId } = buyItems[0];

        const buyAmount = buyItems.length;
        const giftAmount = giftItems.length;

        // eslint-disable-next-line max-len
        return new ComboListItem(
          comboId,
          buyItemId,
          giftItemId,
          buyAmount,
          giftAmount,
          buyAmount,
          giftAmount,
        );
      });

      items.forEach((item) => {
        const { index, completedComboId, isGift } = item;

        const itemList = state.itemsList[index];
        itemList.comboId = completedComboId;
        itemList.isGift = isGift ? 1 : 0;
      });
    },
    LOAD_SAVED_QUOTATION_ITEMS(state, payload) {
      state.itemsList = payload.products;
      state.savedQuotationPurchaseOrderId = payload.id;
    },
    async CLEAR_SAVED_QUOTATION_ITEMS(state) {
      const { store } = state.authUserData.user_claims;
      const data = await PurchaseOrderService.getSavedQuotationsAutocompleteData(
        null,
        store,
      );
      state.savedQuotationsAutocomplete = data;
      state.savedQuotationPurchaseOrderId = null;
    },
    SET_SAVED_QUOTATIONS_AUTOCOMPLETE(state, payload) {
      state.savedQuotationsAutocomplete = payload;
    },
    SET_PRINTER_IP_ADDRESS(state, payload) {
      state.websocketAddress = `ws://${payload}:15674/ws`;
    },
    ADD_SAVED_QUOTATION(state, payload) {
      state.savedQuotationsAutocomplete.push(payload);
    },
    SET_PRODUCTS_SEARCH_TYPE(state, payload) {
      state.productsSearchType = payload;
    },
    SHOW_REPORT_DETAILS_MODAL(state) {
      state.salesReportDetailsModal = true;
    },
    CLOSE_REPORT_DETAILS_MODAL(state) {
      state.salesReportDetailsModal = false;
    },
    SET_REPORT_DETAILS_MODAL_DATA(state, payload) {
      state.salesReportDetailsData = payload;
    },
    SET_SALES_REPORT_LIMIT_AND_PAGE(state, payload) {
      state.salesReportFilters.limit = payload.limit;
      state.salesReportFilters.page = payload.page;
    },
    async SET_SALES_REPORT_TABLE_DATA(state) {
      const filters = state.salesReportFilters;
      if (filters.startDt && filters.endDt) {
        if (filters.startDt !== '' && filters.startDt.split(' ').length === 1) {
          filters.startDt = `${filters.startDt} 00:00:00`;
        }
        if (filters.endDt !== '' && filters.endDt.split(' ').length === 1) {
          filters.endDt = `${filters.endDt} 23:59:59`;
        }
        const tableData = await ReportService.getSalesReportData(
          filters.limit,
          filters.page,
          filters.startDt,
          filters.endDt,
        );
        state.salesReportTableData = tableData;
      }
    },
    async SET_SALES_REPORT_DATA_COUNT(state) {
      const filters = state.salesReportFilters;
      if (filters.startDt && filters.endDt) {
        const tableDataCount = await ReportService.getSalesReportCount(
          filters.startDt,
          filters.endDt,
        );
        state.salesReportTableCount = tableDataCount;
      }
    },
    SET_SALES_REPORT_FILTERS(state, payload) {
      state.salesReportFilters = {
        limit: payload.limit,
        page: payload.page,
        startDt: payload.startDt,
        endDt: payload.endDt,
      };
    },
    SET_CASH_CLOSE_REPORT_LIMIT_AND_PAGE(state, payload) {
      state.cashCloseReportFilters.limit = payload.limit;
      state.cashCloseReportFilters.page = payload.page;
    },
    async SET_CASH_CLOSE_REPORT_TABLE_DATA(state) {
      const filters = state.cashCloseReportFilters;
      if (filters.startDt && filters.endDt) {
        if (filters.startDt !== '' && filters.startDt.split(' ').length === 1) {
          filters.startDt = `${filters.startDt} 00:00:00`;
        }
        if (filters.endDt !== '' && filters.endDt.split(' ').length === 1) {
          filters.endDt = `${filters.endDt} 23:59:59`;
        }
        const tableData = await ReportService.getCashCloseReportData(
          filters.limit,
          filters.page,
          filters.startDt,
          filters.endDt,
        );
        state.cashCloseReportTableData = tableData;
      }
    },
    async SET_CASH_CLOSE_REPORT_DATA_COUNT(state) {
      const filters = state.cashCloseReportFilters;
      if (filters.startDt && filters.endDt) {
        const tableDataCount = await ReportService.getCashCloseReportCount(
          filters.startDt,
          filters.endDt,
        );
        state.cashCloseReportTableCount = tableDataCount;
      }
    },
    SET_CASH_CLOSE_REPORT_FILTERS(state, payload) {
      state.cashCloseReportFilters = {
        limit: payload.limit,
        page: payload.page,
        startDt: payload.startDt,
        endDt: payload.endDt,
      };
    },
    SHOW_CASH_CLOSE_REPORT_DETAILS_MODAL(state) {
      state.cashCloseReportDetailsModal = true;
    },
    CLOSE_CASH_CLOSE_REPORT_DETAILS_MODAL(state) {
      state.cashCloseReportDetailsModal = false;
    },
    SET_CASH_CLOSE_REPORT_DETAILS_MODAL_DATA(state, payload) {
      state.cashCloseReportDetailsData = payload;
    },
    // SET_REPORT_DETAILS_MODAL_DATA(state, payload) {
    //   state.salesReportDetailsData = payload;
    // },
    SET_PRODUCT_REPORT_LIMIT_AND_PAGE(state, payload) {
      state.productReportFilters.limit = payload.limit;
      state.productReportFilters.page = payload.page;
    },
    async SET_PRODUCT_REPORT_TABLE_DATA(state) {
      const filters = state.productReportFilters;
      if (filters.startDt && filters.endDt) {
        if (filters.startDt !== '' && filters.startDt.split(' ').length === 1) {
          filters.startDt = `${filters.startDt} 00:00:00`;
        }
        if (filters.endDt !== '' && filters.endDt.split(' ').length === 1) {
          filters.endDt = `${filters.endDt} 23:59:59`;
        }
        const tableData = await ReportService.getProductReportData(
          filters.limit,
          filters.page,
          filters.startDt,
          filters.endDt,
        );
        state.productReportTableData = tableData;
      }
    },
    async SET_PRODUCT_REPORT_DATA_COUNT(state) {
      const filters = state.productReportFilters;
      if (filters.startDt && filters.endDt) {
        const tableDataCount = await ReportService.getProductReportCount(
          filters.startDt,
          filters.endDt,
        );
        state.productReportTableCount = tableDataCount;
      }
    },
    SET_PRODUCT_REPORT_FILTERS(state, payload) {
      state.productReportFilters = {
        limit: payload.limit,
        page: payload.page,
        startDt: payload.startDt,
        endDt: payload.endDt,
      };
    },
  },
  actions: {
    setAttributes({ commit }, payload) {
      commit('SET_ATTRIBUTES', payload);
    },
    setProductsAutocomplete({ commit }, payload) {
      commit('SET_PRODUCTS_AUTOCOMPLETE', payload);
    },
    setClientsAutocomplete({ commit }, payload) {
      commit('SET_CLIENTS_AUTOCOMPLETE', payload);
    },
    removeItemFromList({ commit }, payload) {
      commit('REMOVE_ITEM_FROM_LIST', payload);
      commit('CLEAR_ITEM_TO_PREVIEW');
    },
    removeItemFromFrontList({ commit }, payload) {
      commit('REMOVE_ITEM_FROM_FRONT_LIST', payload);
      commit('CLEAR_ITEM_TO_PREVIEW');
    },
    removeItemKitFromList({ commit }, payload) {
      commit('REMOVE_ITEM_KIT_FROM_FRONT_LIST', payload);
      commit('CLEAR_ITEM_KIT_TO_PREVIEW');
    },
    addItemToList({ commit }, payload) {
      commit('ADD_ITEM_TO_LIST', payload);
      commit('PREVIEW_ITEM_INFO', payload);
    },
    addItemToListKit({ commit }, payload) {
      commit('ADD_ITEM_TO_LIST_KIT', payload);
    },
    clearItemsToListKit({ commit }) {
      commit('CLEAR_ITEMS_TO_LIST_KIT');
    },
    previewItemInfo({ commit }, payload) {
      commit('PREVIEW_ITEM_INFO', payload);
    },
    clearItemsList({ commit }) {
      commit('CLEAR_ITEMS_LIST');
      commit('CLEAR_ITEM_TO_PREVIEW');
    },
    selectClient({ commit }, payload) {
      commit('SELECT_CLIENT', payload);
      commit('CLEAR_ITEMS_LIST');
      commit('CLEAR_ITEM_TO_PREVIEW');
    },
    setAuthUserData({ commit }, payload) {
      commit('SET_AUTH_USER_DATA', payload);
    },
    clearOrder({ commit }) {
      commit('CLEAR_ORDER');
    },
    setCashCloseData({ commit }, payload) {
      commit('SET_CASH_CLOSE_DATA', payload);
    },
    showOrderDetails({ commit }, payload) {
      commit('SHOW_ORDER_DETAILS', payload);
    },
    closeModal({ commit }) {
      commit('CLOSE_MODAL');
    },
    updateTotalOrderPaid({ commit }, payload) {
      commit('UPDATE_TOTAL_ORDER_PAID', payload);
    },
    clearTotalOrderPaid({ commit }) {
      commit('CLEAR_TOTAL_ORDER_PAID');
    },
    removeFixedNumberItemsFromList({ commit }, payload) {
      commit('REMOVE_FIXED_NUMBER_ITEMS_FROM_LIST', payload);
    },
    calculateCombos({ commit }) {
      commit('CALCULATE_COMBOS');
    },
    modifyPrice({ commit }, payload) {
      commit('MODIFY_PRICE', payload);
    },
    modifyKitProduct({ commit }, payload) {
      const { id, quantity, position } = payload;

      commit('MODIFY_KIT_POSITION', {
        id,
        value: position,
      });
      commit('MODIFY_KIT_QUANTITY', {
        id,
        value: quantity,
      });
    },
    loadSavedQuotationItems({ commit }, payload) {
      commit('LOAD_SAVED_QUOTATION_ITEMS', payload);
    },
    clearSavedQuotationItems({ commit }) {
      commit('CLEAR_SAVED_QUOTATION_ITEMS');
    },
    setSavedQuotationsAutocomplete({ commit }, payload) {
      commit('SET_SAVED_QUOTATIONS_AUTOCOMPLETE', payload);
    },
    setPrinterIPAddress({ commit }, payload) {
      commit('SET_PRINTER_IP_ADDRESS', payload);
    },
    addSavedQuotation({ commit }) {
      commit('CLEAR_SAVED_QUOTATION_ITEMS');
    },
    setProductsSearchType({ commit }, payload) {
      commit('SET_PRODUCTS_SEARCH_TYPE', payload);
    },
    showReportDetailsModal({ commit }) {
      commit('SHOW_REPORT_DETAILS_MODAL');
    },
    closeReportDetailsModal({ commit }) {
      commit('CLOSE_REPORT_DETAILS_MODAL');
    },
    setReportDetailsModalData({ commit }, payload) {
      commit('SET_REPORT_DETAILS_MODAL_DATA', payload);
    },
    setSalesReportLimitPage({ commit }, payload) {
      commit('SET_SALES_REPORT_LIMIT_AND_PAGE', payload);
      commit('SET_SALES_REPORT_TABLE_DATA');
      commit('SET_SALES_REPORT_DATA_COUNT');
    },
    getSalesReport({ commit }, payload) {
      commit('SET_SALES_REPORT_FILTERS', payload);
      commit('SET_SALES_REPORT_TABLE_DATA');
      commit('SET_SALES_REPORT_DATA_COUNT');
    },
    setCashCloseReportLimitPage({ commit }, payload) {
      commit('SET_CASH_CLOSE_REPORT_LIMIT_AND_PAGE', payload);
      commit('SET_CASH_CLOSE_REPORT_TABLE_DATA');
      commit('SET_CASH_CLOSE_REPORT_DATA_COUNT');
    },
    getCashCloseReport({ commit }, payload) {
      commit('SET_CASH_CLOSE_REPORT_FILTERS', payload);
      commit('SET_CASH_CLOSE_REPORT_TABLE_DATA');
      commit('SET_CASH_CLOSE_REPORT_DATA_COUNT');
    },
    showCashCloseReportDetailsModal({ commit }) {
      commit('SHOW_CASH_CLOSE_REPORT_DETAILS_MODAL');
    },
    closeCashCloseReportDetailsModal({ commit }) {
      commit('CLOSE_CASH_CLOSE_REPORT_DETAILS_MODAL');
    },
    setCashCloseReportDetailsModalData({ commit }, payload) {
      commit('SET_CASH_CLOSE_REPORT_DETAILS_MODAL_DATA', payload);
    },
    getProductReport({ commit }, payload) {
      commit('SET_PRODUCT_REPORT_FILTERS', payload);
      commit('SET_PRODUCT_REPORT_TABLE_DATA');
      commit('SET_PRODUCT_REPORT_DATA_COUNT');
    },
    setProductReportLimitPage({ commit }, payload) {
      commit('SET_PRODUCT_REPORT_LIMIT_AND_PAGE', payload);
      commit('SET_PRODUCT_REPORT_TABLE_DATA');
      commit('SET_PRODUCT_REPORT_DATA_COUNT');
    },

  },
  getters: {
    productsList: state => state.productsList,
    itemsList: state => state.itemsList,
    itemsListKit: state => state.itemsListKit,
    frontItemsList: (state) => {
      state.itemsList.forEach(clearAlreadyInList);

      let frontItems = state.itemsList.map((x) => {
        let quantity = 0;
        if (!x.alreadyInList) {
          if (x.isGift === 1) {
            // eslint-disable-next-line max-len
            const itemsList = state.itemsList.filter(
              item => item.id === x.id && item.isGift === 1,
            );
            quantity = itemsList.length;
            itemsList.forEach(setAlreadyInList);
          } else {
            // get items if not gifts
            const totalItems = state.itemsList.filter(
              item => x.id === item.id && !item.isGift,
            );
            quantity = totalItems.length;
            totalItems.forEach(setAlreadyInList);
          }

          return makeFrontItemFromItem(x, quantity);
        }

        return null;
      });

      frontItems = frontItems.filter(item => !!item);

      return frontItems;
    },
    frontItemsKitList: state => state.itemsListKit.map(x => makeFrontItemFromItem(x, x.quantity)),
    cartTotalKit: (state) => {
      const total = state.itemsListKit.reduce(
        (acc, item) => acc + item.baseCost * item.quantity,
        0,
      );

      return {
        total,
        FTotal: CurrencyFormatter.format(total),
      };
    },
    cartTotal: (state) => {
      let total = state.itemsList.reduce((acc, item) => acc + item.baseCost, 0);

      const discounts = state.itemsList.map((item) => {
        // Porcentaje de descuento a usar
        let discountToUse = 0;
        // Si el producto es un regalo (Ej: regalo de combo), se le da el 100% de descuento
        if (item.isGift) {
          discountToUse = 100;
        } else if (item.discountSpecials.length > 0) {
          discountToUse = item.discountSpecials[0].discount;

          // Si el producto tiene descuento y el cliente seleccionado tambien
          // se evalua cual de los dos es el mayor y ese es el que se usará
          if (state.selectedClient && state.selectedClient.discount > 0) {
            if (
              state.selectedClient.discount > item.discountSpecials[0].discount
            ) {
              discountToUse = state.selectedClient.discount;
            }
          }
        } else if (state.selectedClient && state.selectedClient.discount > 0) {
          // Si el producto no tiene descuento pero el cliente si,
          // se usará el descuento del cliente
          discountToUse = state.selectedClient.discount;
        }

        // Se obtiene el descuento total (en pesos) en base al porcentaje
        // y se multiplica por la cantidad de articulos ingresados para obtener el descuento total
        let discount = FPUtils.getItemDiscount(item.baseCost, discountToUse);
        discount *= item.quantity;

        const item2 = item;
        item2.calculatedDiscount = discount;

        return discount;
      });

      const discountsTotal = discounts.reduce(
        (acc, discount) => acc + discount,
        0,
      );
      total -= discountsTotal;

      return {
        total,
        FTotal: CurrencyFormatter.format(total),
      };
    },
    selectedClient: state => state.selectedClient,
    itemToPreview: state => state.itemToPreview,
    clientsList: state => state.clientsList,
    authUserData: state => state.authUserData,
    cashCloseData: state => state.cashCloseData,
    orderDetails: state => state.orderDetails,
    orderDetailsModal: state => state.orderDetailsModal,
    totalOrderPaid: state => state.totalOrderPaid,
    FTotalOrderPaid: state => CurrencyFormatter.format(state.totalOrderPaid),
    savedQuotationPurchaseOrderId: state => state.savedQuotationPurchaseOrderId,
    savedQuotationsAutocomplete: state => state.savedQuotationsAutocomplete,
    websocketAddress: state => state.websocketAddress,
    productsSearchType: state => state.productsSearchType,
    salesReportDetailsModal: state => state.salesReportDetailsModal,
    salesReportDetailsData: state => state.salesReportDetailsData,
    salesReportTableData: state => state.salesReportTableData,
    salesReportTableCount: state => state.salesReportTableCount,
    salesReportTableFilters: state => state.salesReportFilters,
    cashCloseReportTableData: state => state.cashCloseReportTableData,
    cashCloseReportTableCount: state => state.cashCloseReportTableCount,
    cashCloseReportTableFilters: state => state.cashCloseReportFilters,
    cashCloseReportDetailsModal: state => state.cashCloseReportDetailsModal,
    cashCloseReportDetailsData: state => state.cashCloseReportDetailsData,
    productReportDetailsModal: state => state.productReportDetailsModal,
    productReportDetailsData: state => state.productReportDetailsData,
    productReportTableData: state => state.productReportTableData,
    productReportTableCount: state => state.productReportTableCount,
    productReportTableFilters: state => state.productReportFilters,

    attributes: state => state.attributes,

    canViewProducts: state => state.attributes.includes('ADMIN_PRODUCTS'),
    canViewClientOrders: state => state.attributes.includes('ADMIN_CLIENT_ORDERS'),
    canViewEmployees: state => state.attributes.includes('ADMIN_EMPLOYEES'),
    canViewSalesOrders: state => state.attributes.includes('ADMIN_SALES_ORDERS'),
    canViewPos: state => state.attributes.includes('ADMIN_POS'),
    canViewKits: state => state.attributes.includes('ADMIN_KITS'),
    canViewCatalogs: state => state.attributes.includes('ADMIN_CATALOGS'),
    canViewPromotions: state => state.attributes.includes('ADMIN_PROMOTIONS'),
    canViewInventory: state => state.attributes.includes('ADMIN_INVENTORY'),
    canViewDelivery: state => state.attributes.includes('ADMIN_DELIVERY'),
    canViewSalesOrdersAdmin: state => state.attributes.includes('ADMIN_SALES_ORDERS_ADMIN'),

    canRegisterSalesOrdersDispatches: state => state.attributes.includes('CAN_REGISTER_SALES_ORDERS_DISPATCH'),
    canCloseSalesOrdersDispatches: state => state.attributes.includes('CAN_CLOSE_SALES_ORDERS_DISPATCH'),

    canViewDrivers: state => state.attributes.includes('ADMIN_DRIVER_MAP'),

    salesOrdersAdminType: ({ attributes }) => {
      const canViewSalesOrdersAdmin = attributes.includes(
        'ADMIN_SALES_ORDERS_ADMIN',
      );
      const canRegisterSalesOrdersDispatches = attributes.includes(
        'CAN_REGISTER_SALES_ORDERS_DISPATCH',
      );
      const canCloseSalesOrdersDispatches = attributes.includes(
        'CAN_CLOSE_SALES_ORDERS_DISPATCH',
      );

      if (!canViewSalesOrdersAdmin) {
        return 0;
      }

      if (canRegisterSalesOrdersDispatches && canCloseSalesOrdersDispatches) {
        return 3;
      }

      if (canRegisterSalesOrdersDispatches) {
        return 1;
      }

      if (canCloseSalesOrdersDispatches) {
        return 2;
      }

      return 0;
    },

    canAuthorizeWarehouse: state => state.attributes.includes('AUTH_WAREHOUSE'),
    canAuthorizeDirector: state => state.attributes.includes('AUTH_DIRECTOR'),
  },
  modules: {
    salesOrders,
    pos,
    delivery,
  },
});
