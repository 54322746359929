import CurrencyFormatter from './CurrencyFormatter';

export class SalesReportPaymentsData {
  constructor(
    totalAmount,
    cashAmount,
    debitAmount,
    creditAmount,
    checkAmount,
    transferAmount,
  ) {
    this.totalAmount = totalAmount;
    this.cashAmount = cashAmount;
    this.debitAmount = debitAmount;
    this.creditAmount = creditAmount;
    this.checkAmount = checkAmount;
    this.transferAmount = transferAmount;
  }

  get totalAmountStr() {
    if (this.totalAmount) {
      return CurrencyFormatter.format(this.totalAmount);
    }
    return CurrencyFormatter.format(0);
  }

  get cashAmountStr() {
    if (this.cashAmount) {
      return CurrencyFormatter.format(this.cashAmount);
    }
    return CurrencyFormatter.format(0);
  }

  get debitAmountStr() {
    if (this.debitAmount) {
      return CurrencyFormatter.format(this.debitAmount);
    }
    return CurrencyFormatter.format(0);
  }

  get creditAmountStr() {
    if (this.creditAmount) {
      return CurrencyFormatter.format(this.creditAmount);
    }
    return CurrencyFormatter.format(0);
  }

  get checkAmountStr() {
    if (this.checkAmount) {
      return CurrencyFormatter.format(this.checkAmount);
    }
    return CurrencyFormatter.format(0);
  }

  get transferAmountStr() {
    if (this.transferAmount) {
      return CurrencyFormatter.format(this.transferAmount);
    }
    return CurrencyFormatter.format(0);
  }
}
