<template>
  <div v-if="selectedClientOrder" class="d-flex row align-content-center">
        <span class="white--text text-h6 align-center pl-2">
          Order #{{ selectedClientOrder.id }} - {{ selectedClientOrder.clientName }}
          <b>{{ selectedClientOrder.delivery.deliveryTypeLabel }}</b>
        </span>
    <v-spacer></v-spacer>
    <v-btn outlined dark
      color="secondary"
      class="ens-cobsd-view-info-btn mr-2"
      @click="toggleInfoDrawer">
      <v-icon class="">mdi-eye</v-icon>
      <span>Info</span>
    </v-btn>
    <v-btn outlined dark
      color="secondary"
      class="ens-cobsd-printer-btn mr-2"
      :loading="printerWorking"
      @click="onPrinter">
      <v-icon>mdi-printer</v-icon>
      <span v-if="!printerConnected">Connect Printer</span>
      <span v-else>Print test</span>
    </v-btn>
    <v-btn color="blue" dark class="ens-cobsd-save-btn" @click="saveData">
      Save
    </v-btn>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import PrintUtils from '@/utils/PrintUtils';
import DeliveryService from '@/services/DeliveryService';
import { CreateDeliveryOrderRequestData } from '@/models/delivery/CreateDeliveryOrderRequestData';


export default {
  data: () => ({
    successSwalTimer: 1500,
  }),

  computed: {
    ...mapGetters({
      selectedClientOrder: 'selectedClientOrder',
      packages: 'packages',

      printerEndpoint: 'printerEndpoint',
      printerConnected: 'printerConnected',
      printerWorking: 'printerWorking',
      printerDeviceData: 'printerDeviceData',
    }),
  },

  methods: {
    ...mapActions([
      'toggleInfoDrawer',
      'loadClientOrderPacking',
      'checkPrinterConnection',
      'clearUnsavedChanges',
    ]),

    async onPrinter() {
      if (!this.printerConnected) {
        await this.checkPrinterConnection();
        return;
      }

      await this.onTestPrinter();
    },

    async onTestPrinter() {
      const testLabel = PrintUtils.makeZplForTest();
      await DeliveryService.sendLabelsToPrinter(this.printerEndpoint, this.printerDeviceData, [testLabel]);
    },

    async saveData() {
      const clientOrderId = this.selectedClientOrder.id;

      const requestData = new CreateDeliveryOrderRequestData();
      requestData.clientOrderId = clientOrderId;
      requestData.packages = this.packages.map(item => ({
        id: item.id > 0 ? item.id : null,
        code: item.code,
        length: item.length ?? 0,
        width: item.width ?? 0,
        height: item.height ?? 0,
        weight: item.weight ?? 0,
        products: item.products.map(itemProduct => ({
          clientOrderProductId: itemProduct.id,
          quantity: itemProduct.quantity,
        })),
      }));

      let response;
      try {
        response = await DeliveryService.createDeliveryOrderPackages(requestData);
      } catch (e) {
        this.$snotify.error(e.response.data.message, 'Error');

        return;
      }

      this.$snotify.success(response.message);

      await this.loadClientOrderPacking(clientOrderId);
      this.clearUnsavedChanges();
    },
  },

  name: 'ClientOrderToolbar',
};
</script>

<style scoped>

</style>
