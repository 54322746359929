export class ComboListItem {
  constructor(comboId, productId, giftProductId, buyAmount, giftAmount, boughtTotal, giftTotal) {
    this.comboId = comboId;
    this.productId = productId;
    this.giftProductId = giftProductId;
    this.buyAmount = buyAmount;
    this.giftAmount = giftAmount;
    this.boughtTotal = boughtTotal;
    this.giftTotal = giftTotal;
  }
}
