export const DeliveryType = {
  UNKNOWN: null,
  POS: 0,
  LOCAL: 1,
  REMOTE: 2,
};

export class ClientOrderDelivery {
  constructor() {
    this.id = 0;
    this.addressMain = '';
    this.addressOptional = '';
    this.cityName = '';
    this.stateName = '';
    this.zipCode = '';
    this.deliveryType = DeliveryType.UNKNOWN;
    this.email = '';
    this.phoneNumber = '';
    this.contactName = '';
    this.companyName = '';
  }

  get addressFull() {
    const addressOptional = this.addressOptional ?? '';
    if (!addressOptional) {
      return this.addressMain;
    }

    return `${this.addressMain}, ${this.addressOptional}`;
  }

  get addressComplementary() {
    // not local or remote address, return empty
    if (this.deliveryType !== DeliveryType.LOCAL && this.deliveryType !== DeliveryType.REMOTE) {
      return '-';
    }

    return `${this.cityName ?? ''}, ${this.stateName ?? ''} ${this.zipCode ?? ''}`.trim();
  }

  get deliveryTypeLabel() {
    if (this.deliveryType === DeliveryType.POS) {
      return 'POS Order';
    }

    if (this.deliveryType === DeliveryType.LOCAL) {
      return 'Local Delivery';
    }

    if (this.deliveryType === DeliveryType.REMOTE) {
      return 'Shipping';
    }

    return 'Delivery Type not defined';
  }

  get contactNameLabel() {
    if (!this.companyName) {
      return this.contactName;
    }

    return `${this.companyName} (${this.contactName})`;
  }
}
