import Axios, { makeAxiosConfig } from '@/services/Axios';
import {
  getDeliveryClientOrderPackingSerializer,
  getDeliveryClientsOrdersSerializer,
  getDeliveryOrdersPackagesSerializer,
  getDeliveryOrdersSerializer,
} from '@/serializers/Delivery.serializer';
import { makeCountResponseFromData, messageResponseSerializer } from '@/serializers/General.serializer';

export default {
  /**
   * @returns {Promise<ClientOrder[]>}
   */
  async getDeliveryClientsOrders(searchQuery, startDate, endDate, status) {
    const config = makeAxiosConfig({}, getDeliveryClientsOrdersSerializer);
    config.params = {
      status,
      search_query: searchQuery ?? '',
      start_date: startDate ?? '',
      end_date: endDate ?? '',
    };

    const response = await Axios.get('/delivery/clients-orders', config);

    return response.data;
  },

  /**
   * @returns {Promise<ClientOrder[]>}
   */
  async getClientOrderTableData(searchQuery, startDate, endDate, status, limit, offset) {
    const config = makeAxiosConfig({}, getDeliveryClientsOrdersSerializer);
    config.params = {
      status,
      search_query: searchQuery ?? '',
      start_date: startDate ?? '',
      end_date: endDate ?? '',
      limit,
      offset,
    };

    const response = await Axios.get('/client-order/table-data', config);

    return response.data;
  },

  async getClientOrderTableCount(searchQuery, startDate, endDate, status) {
    const config = makeAxiosConfig({}, makeCountResponseFromData);
    config.params = {
      status,
      search_query: searchQuery ?? '',
      start_date: startDate ?? '',
      end_date: endDate ?? '',
    };

    const response = await Axios.get('/client-order/table-count', config);

    return response.data;
  },

  /**
   * @returns {Promise<ClientOrder>}
   */
  async getDeliveryClientOrderPacking(clientOrderId) {
    const config = makeAxiosConfig({}, getDeliveryClientOrderPackingSerializer);

    const response = await Axios.get(`/delivery/client-order/${clientOrderId}/packing`, config);

    return response.data;
  },

  async getDeliveryOrders() {
    const config = makeAxiosConfig({}, getDeliveryOrdersSerializer);
    const response = await Axios.get('/delivery/delivery-orders', config);
    return response.data;
  },

  /**
   * @param {number} limit
   * @param {number} offset
   * @param {number} regStatus
   * @returns {Promise<DeliveryOrder[]>}
   */
  async getDeliveryOrderTableData(limit, offset, regStatus) {
    const config = makeAxiosConfig({}, getDeliveryOrdersSerializer);
    config.params = {
      limit,
      offset,
      reg_status: regStatus,
    };

    const response = await Axios.get('/delivery-order/table-data', config);
    return response.data;
  },

  /**
   * @param {number} regStatus
   * @returns {Promise<number>}
   */
  async getDeliveryOrderTableCount(regStatus) {
    const config = makeAxiosConfig({}, makeCountResponseFromData);

    config.params = {
      reg_status: regStatus,
    };

    const { data } = await Axios.get('/delivery-order/table-count', config);
    return data.count;
  },


  async getDeliveryMap(deliveryOrderId = null) {
    const config = makeAxiosConfig({});
    config.params = {
      deliveryOrderId,
    };
    const response = await Axios.get('/delivery/google-maps', config);
    return response.data;
  },

  async getDeliveryOrdersPackages(regStatus, onlyOrphan = 0, includeProducts = 1) {
    const config = makeAxiosConfig({}, getDeliveryOrdersPackagesSerializer);

    config.params = {
      reg_status: regStatus,
      only_orphan: onlyOrphan,
      include_products: includeProducts,
    };

    const response = await Axios.get('/delivery/delivery-order-packages', config);

    return response.data;
  },

  /**
   * @param {CreateDeliveryOrderRequestData} requestData
   * @return {Promise<any>}
   */
  async createDeliveryOrderPackages(requestData) {
    const config = makeAxiosConfig({});

    const response = await Axios.post('/delivery/delivery-order-packages', requestData, config);

    return response.data;
  },
  async completeDeliveryOrder(deliveryIds) {
    const config = makeAxiosConfig({});
    const requestData = { ids: deliveryIds };

    const response = await Axios.post('/delivery-order-package/complete', requestData, config);

    return response.data;
  },
  async rejectDeliveryOrder(deliveryIds) {
    const config = makeAxiosConfig({});
    const requestData = { ids: deliveryIds };

    const response = await Axios.post('/delivery-order-package/reject', requestData, config);

    return response.data;
  },

  /**
   * @param {number[]} ids
   * @returns {Promise<any>}
   */
  async getDeliveryOrderPackageMapsRoute(ids) {
    const config = makeAxiosConfig({});
    config.params = {
      ids: !ids ? [] : ids.join(','),
    };

    const response = await Axios.get('/delivery-order-package/maps-route', config);

    return response.data;
  },

  async getDeliveryOrdersMap(deliveryOrderId) {
    const config = makeAxiosConfig({});
    const response = await Axios.get(`/delivery-order/${deliveryOrderId}/maps-route`, config);

    return response.data;
  },

  /**
   * @param {number[]} packages
   * @returns {Promise<MessageResponse>}
   */
  async createDeliveryOrder(packages) {
    const config = makeAxiosConfig({}, messageResponseSerializer);

    const reqBody = {
      packages,
    };

    const response = await Axios.post('/delivery-order', reqBody, config);

    return response.data;
  },

  /**
   * @param {!string} printerEndpoint
   * @param {!Object} deviceData
   * @param {!string[]} zplLabels
   * @return {Promise<void>}
   */
  // eslint-disable-next-line no-unused-vars
  async sendLabelsToPrinter(printerEndpoint, deviceData, zplLabels) {
    const headers = {
      'Content-Type': 'text/plain',
    };

    const baseRequest = {
      device: deviceData,
      data: null,
    };

    const requests = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const item of zplLabels) {
      const requestData = { ...baseRequest };
      requestData.data = item;

      requests.push(Axios.post(`${printerEndpoint}/write`, requestData, { headers }));
    }

    await Promise.all(requests);
  },

  async getPrinterDeviceData(printerEndpoint) {
    const response = await Axios.get(`${printerEndpoint}/available`);
    return response.data.printer && response.data.printer.length > 0 ? response.data.printer[0] : null;
  },


  /**
   * @param {int} deliveryOrderId
   * @param {int} regStatus
   * @return {Promise<any>}
   */
  async updateDeliveryOrderStatus(deliveryOrderId, regStatus) {
    const config = makeAxiosConfig({});

    const requestData = { value: regStatus };

    const response = await Axios.put(`/delivery-order/${deliveryOrderId}/status`, requestData, config);

    return response.data;
  },

  async getPackageUpsLabel(deliveryOrderPackageId) {
    const config = makeAxiosConfig({});

    const response = await Axios.post(
      `/delivery-order-package/${deliveryOrderPackageId}/ups-label`,
      null,
      config,
    );

    return response.data;
  },

  /**
   * @param {int} clientOrderId
   * @return {Promise<any>}
   */
  async closeClientOrder(clientOrderId) {
    const config = makeAxiosConfig({});

    const response = await Axios.post(`/client-order/${clientOrderId}/close`, null, config);

    return response.data;
  },
  /*  */
  async orderComplete(deliveryOrderId, recipient) {
    const config = makeAxiosConfig({});

    const requestData = { delivered_to: recipient };

    const response = await Axios.post(`/delivery-order-package/${deliveryOrderId}/complete`, requestData, config);

    return response.data;
  },
  async orderReject(deliveryOrderId, comment) {
    const config = makeAxiosConfig({});

    const requestData = { comments: comment };

    const response = await Axios.post(`/delivery-order-package/${deliveryOrderId}/reject`, requestData, config);

    return response.data;
  },
  async orderFinalize(deliveryOrderId) {
    const config = makeAxiosConfig({});

    // const requestData = { value: regStatus };

    const response = await Axios.post(`/delivery-order/${deliveryOrderId}/finalize`, {}, config);

    return response.data;
  },

  /**
   * @param {number} clientOrderId
   * @returns {Promise<MessageResponse>}
   */
  async deleteClientOrder(clientOrderId) {
    const config = makeAxiosConfig({}, messageResponseSerializer);

    const response = await Axios.delete(`/client-order/${clientOrderId}`, config);

    return response.data;
  },

};
