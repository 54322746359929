<template>
  <div>
    <v-toolbar color="primary">
      <v-app-bar-nav-icon dark @click.stop="drawer = !drawer">
      </v-app-bar-nav-icon>
      <client-order-toolbar v-if="selectedClientOrder"></client-order-toolbar>
    </v-toolbar>
    <v-navigation-drawer
      v-model="drawer"
      app
      dark
      absolute
      temporary
      color="primary"
    >
      <v-list dense nav class="py-0">
        <v-list-item two-line :class="'px-0'">
          <v-list-item-avatar tile>
            <img src="./../assets/logo.png" height="100" />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>Menu</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item to="/dashboard" v-if="canViewProducts">
          <v-list-item-icon>
            <v-icon>mdi-package-variant</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Products</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/users" v-if="canViewCatalogs">
          <v-list-item-icon>
            <v-icon>mdi-account-group</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Users</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/banners" v-if="canViewCatalogs">
          <v-list-item-icon>
            <v-icon>mdi-format-float-center</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Banners</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link to="/client-orders" v-if="canViewClientOrders">
          <v-list-item-icon>
            <v-icon color="white">mdi-account</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="white-text">
              Client Orders
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/sales-orders" v-if="canViewSalesOrders">
          <v-list-item-icon>
            <v-icon>mdi-cash</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Sales Orders</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/pos" v-if="canViewPos">
          <v-list-item-icon>
            <v-icon>mdi-store</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>POS</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!------------------------ CATALOGOS -------------------------------------------->
        <v-list-group :value="false" v-if="canViewCatalogs">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon color="white"> mdi-alphabetical</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="white-text">
              Base catalogs
            </v-list-item-title>
          </template>

          <v-list dense nav class="py-0 ml-4">
            <v-list-item link to="/tags-home">
              <v-list-item-icon>
                <v-icon color="white"> mdi-tag</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="white-text">Tags</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link to="/brands-home">
              <v-list-item-icon>
                <v-icon color="white"> mdi-alpha-m-box</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="white-text">Brands</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link to="/suppliers-home">
              <v-list-item-icon>
                <v-icon color="white"> mdi-truck</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="white-text"
                  >Suppliers
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link to="/measuring-units-home">
              <v-list-item-icon>
                <v-icon color="white"> mdi-weight-kilogram</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="white-text">
                  Measurement Units
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-list-group>
        <!------------------------ CATALOGOS -------------------------------------------->

        <!------------------------ DELIVERY -------------------------------------------->
        <v-list-group :value="false" v-if="canViewDelivery">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon color="white"> mdi-truck</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="white-text">Delivery</v-list-item-title>
          </template>

          <v-list dense nav class="py-0 ml-4">
            <v-list-item link to="/delivery/client-orders">
              <v-list-item-icon>
                <v-icon color="white"> mdi-archive</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="white-text">
                  Set Up Packages
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              id="ens-anmi-delivery-tracking"
              link
              to="/delivery/tracking"
            >
              <v-list-item-icon>
                <v-icon color="white"> mdi-truck</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="white-text">
                  Dispatch / Tracking
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-list-group>
        <!------------------------ INVENTARIO -------------------------------------------->


        <!-----------------------------------------------Reports -------------------------------->
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon color="white">mdi-chart-bar</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="white-text">Reports</v-list-item-title>
          </template>

          <v-list dense nav class="py-0 ml-4">
            <v-list-item
              id="ens-anmi-report-sales"
              link
              to="/reports/sales"
            >
              <v-list-item-icon>
                <v-icon color="white">mdi-currency-usd</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="white-text">Sales</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-list-group>
        <!------------------------ INVENTARIO -------------------------------------------->
        <v-list-group :value="false" v-if="canViewInventory">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon color="white"> mdi-file-document</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="white-text">Warehouse</v-list-item-title>
          </template>

          <v-list dense nav class="py-0 ml-4">
            <v-list-item
              link
              to="/stores-inventory"
              id="ens-anmi-stores-inventory"
            >
              <v-list-item-icon>
                <v-icon color="white"> mdi-store</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="white-text">
                  Inventory
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-group :value="false">
              <template v-slot:activator>
                <v-list-item-icon>
                  <v-icon color="white"> mdi-file-document</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="white-text">
                  Suppliers
                </v-list-item-title>
              </template>

              <v-list dense nav class="py-0 ml-4">
                <v-list-item link to="/purchase-order">
                  <v-list-item-icon>
                    <v-icon color="white"> mdi-cash</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title class="white-text">
                      Create PO
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item link to="/purchase-orders">
                  <v-list-item-icon>
                    <v-icon color="white"> mdi-cash-multiple</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title class="white-text">
                      Process PO
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-list-group>
          </v-list>
        </v-list-group>
        <!------------------------ INVENTARIO -------------------------------------------->
        <!--Google Maps-->
        <v-list-item to="/drivers-home" v-if="canViewDrivers">
          <v-list-item-icon>
            <v-icon>mdi-steering</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Drivers Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/login">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ClientOrderToolbar from '@/components/toolbars/ClientOrderToolbar.vue';

const computed = {
  ...mapGetters({
    canViewProducts: 'canViewProducts',
    canViewClientOrders: 'canViewClientOrders',
    canViewEmployees: 'canViewEmployees',
    canViewSales: 'canViewSales',
    canViewSalesOrders: 'canViewSalesOrders',
    canViewPos: 'canViewPos',
    canViewQuotations: 'canViewQuotations',
    canViewKits: 'canViewKits',
    canViewCatalogs: 'canViewCatalogs',
    canViewPromotions: 'canViewPromotions',
    canViewInventory: 'canViewInventory',
    canViewDelivery: 'canViewDelivery',
    canViewDrivers: 'canViewDrivers',
    salesOrdersAdminType: 'salesOrdersAdminType',

    selectedClientOrder: 'selectedClientOrder',
  }),
};

export default {
  components: { ClientOrderToolbar },
  computed,

  name: 'app-navigation',
  data: () => ({
    drawer: null,
    miniVariant: false,
  }),
};
</script>

<style lang="less">
.v-list .v-list-item--active,
.v-list .v-list-item--active .v-icon {
  color: white !important;
}

.white-text {
  color: white !important;
}
</style>
