import { DeliveryOrderPackage } from '@/models/delivery/DeliveryOrderPackage';
import { v4 as uuidv4 } from 'uuid';
import PrintUtils from '@/utils/PrintUtils';
import { delay } from '@/utils/AppUtils';
import DeliveryService from '@/services/DeliveryService';

export const types = {
  SET_UNSAVED_CHANGES: 'SET_UNSAVED_CHANGES',
  SET_SELECTED_CLIENT_ORDER: 'SET_SELECTED_CLIENT_ORDER',
  ADD_PACKAGE: 'ADD_PACKAGE',
  SET_PACKAGES: 'SET_PACKAGES',

  SET_PRINTER_DEVICE_DATA: 'SET_PRINTER_DEVICE_DATA',
  SET_PRINTER_CONNECTED: 'SET_PRINTER_CONNECTED',
  SET_PRINTER_WORKING: 'SET_PRINTER_WORKING',

  SET_INFO_DRAWER: 'SET_INFO_DRAWER',
};

const actions = {
  toggleInfoDrawer(context) {
    const { state } = context;
    context.commit(types.SET_INFO_DRAWER, !state.infoDrawer);
  },

  async clearPackages({ commit }) {
    commit(types.SET_PACKAGES, []);
  },

  async loadClientOrderPacking({ commit }, payload) {
    const response = await DeliveryService.getDeliveryClientOrderPacking(payload);

    commit(types.SET_SELECTED_CLIENT_ORDER, response);

    const missingPackaging = response.products.filter(product => !product.isEverythingPackaged);
    if (!missingPackaging.length) {
      commit(types.SET_UNSAVED_CHANGES, false);
    }
  },

  async selectClientOrder({ commit }, payload) {
    commit(types.SET_SELECTED_CLIENT_ORDER, payload);
  },

  addPackage({ commit }, payload) {
    commit(types.ADD_PACKAGE, payload);
    commit(types.SET_UNSAVED_CHANGES, true);
  },

  async createPackage({ commit }, payload) {
    const newPackage = new DeliveryOrderPackage();
    newPackage.code = uuidv4();
    newPackage.clientName = payload.clientName;
    newPackage.address = payload.delivery;
    newPackage.boxNumber = payload.boxNumberCounter;

    commit(types.ADD_PACKAGE, newPackage);
    commit(types.SET_UNSAVED_CHANGES, true);
  },

  removePackage({
    commit,
    state,
  }, payload) {
    const tmpPackages = [...state.packages];
    tmpPackages.splice(state.packages.indexOf(payload), 1);

    commit(types.SET_PACKAGES, tmpPackages);
    commit(types.SET_UNSAVED_CHANGES, true);
  },

  setPackages({ commit }, payload) {
    commit(types.SET_PACKAGES, payload);
  },

  setPrinterDeviceData({ commit }, payload) {
    commit(types.SET_PRINTER_DEVICE_DATA, payload);

    if (!!payload) {
      commit(types.SET_PRINTER_CONNECTED, !!payload);
    }
  },

  setPrinterWorking({ commit }, payload) {
    commit(types.SET_PRINTER_WORKING, payload);
  },

  async checkPrinterConnection({
    commit,
    state,
  }) {
    // if already connected, just exit
    if (state.printerConnected) return;

    // else, try to connect
    const win = window.open(`${state.printerEndpoint}/available`);
    setTimeout(() => {
      win.close();
    }, 5000);

    commit(types.SET_PRINTER_WORKING, true);
    await delay(5000);

    const printerDeviceData = await DeliveryService.getPrinterDeviceData(this.printerEndpoint);
    commit(types.SET_PRINTER_DEVICE_DATA, printerDeviceData);
    commit(types.SET_PRINTER_CONNECTED, !!printerDeviceData);

    await delay(500);
    commit(types.SET_PRINTER_WORKING, false);
  },

  setUnsavedChanges({ commit }) {
    commit(types.SET_UNSAVED_CHANGES, true);
  },

  clearUnsavedChanges({ commit }) {
    commit(types.SET_UNSAVED_CHANGES, false);
  },
};

const mutations = {
  [types.SET_SELECTED_CLIENT_ORDER](state, payload) {
    state.selectedClientOrder = payload;
  },
  [types.ADD_PACKAGE](state, payload) {
    state.packages = [...state.packages, payload];
  },
  [types.SET_PACKAGES](state, payload) {
    state.packages = payload;
  },
  [types.SET_PRINTER_DEVICE_DATA](state, payload) {
    state.printerDeviceData = payload;
  },
  [types.SET_PRINTER_CONNECTED](state, payload) {
    state.printerConnected = payload;
  },
  [types.SET_PRINTER_WORKING](state, payload) {
    state.printerWorking = payload;
  },
  [types.SET_UNSAVED_CHANGES](state, payload) {
    state.unsavedChanges = payload;
  },
  [types.SET_INFO_DRAWER](state, payload) {
    state.infoDrawer = payload;
  },
};

const getters = {
  unsavedChanges: state => state.unsavedChanges,
  selectedClientOrder: state => state.selectedClientOrder,
  packages: state => state.packages,

  printerEndpoint: state => state.printerEndpoint,
  printerConnected: state => state.printerConnected,
  printerWorking: state => state.printerWorking,
  printerDeviceData: state => state.printerDeviceData,
};

const printerEndpoint = PrintUtils.getPrinterEndpointFromMeta();

export default {
  actions,
  mutations,
  getters,

  state: {
    infoDrawer: false,

    unsavedChanges: false,
    selectedClientOrder: null,
    packages: [],

    printerEndpoint,
    printerConnected: false,
    printerWorking: false,
    printerDeviceData: null,
  },
};
