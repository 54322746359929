export class ClientOrderProduct {
  constructor() {
    this.id = 0;
    this.alreadyPackaged = 0;
    this.quantity = 0;
    this.productId = 0;
    this.productName = '';
    this.productMeasuringUnitName = '';
    this.productLocation = '';
    this.productBarcode = '';
    this.productWeight = null;
    this.imageUrl = '';
  }

  get isEverythingPackaged() {
    return this.alreadyPackaged >= this.quantity;
  }

  get totalWeight() {
    // for heavy weight or invalid weight products
    if (!this.productWeight || this.productWeight <= 0) return 0;

    return this.productWeight * this.quantity;
  }

  clone() {
    const inst = new ClientOrderProduct();

    inst.id = this.id;
    inst.alreadyPackaged = this.alreadyPackaged;
    inst.quantity = this.quantity;
    inst.productId = this.productId;
    inst.productName = this.productName;
    inst.productMeasuringUnitName = this.productMeasuringUnitName;
    inst.productLocation = this.productLocation;
    inst.productBarcode = this.productBarcode;
    inst.productWeight = this.productWeight;
    inst.imageUrl = this.imageUrl;

    return inst;
  }
}
