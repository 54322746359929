import CurrencyFormatter from '@/models/CurrencyFormatter';

export class CartItem {
  constructor() {
    /** @type {!number} */
    this.id = 0;

    /** @type {!number} */
    this.quantity = 0;

    /** @type {!string} */
    this.name = '';

    /** @type {!string} */
    this.model = '';

    /** @type {!number} */
    this.baseCost = 0;

    /** @type {?number} */
    this.realPrice = null;

    /** @type {?number} */
    this.discountRate = null;

    /** @type {!number} */
    this.isGift = 0;

    this.image = null;

    this.brand = null;

    /** @type {string} */
    this.measuringUnitName = '';
  }

  get baseCostStr() {
    return CurrencyFormatter.format(this.baseCost);
  }

  get total() {
    return this.quantity * this.baseCost;
  }

  get costStr() {
    return CurrencyFormatter.format(this.total);
  }

  // eslint-disable-next-line class-methods-use-this
  get costWithDiscountStr() {
    return '';
  }

  // eslint-disable-next-line class-methods-use-this
  get discountStr() {
    return '';
  }

  // eslint-disable-next-line class-methods-use-this
  get calculatedDiscount() {
    return 0;
  }

  get imageUrl() {
    if (!this.image) {
      // todo: set own placeholder image
      return 'https://via.placeholder.com/250';
    }

    return this.image.fileUrl;
  }
}
