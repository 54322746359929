export const ClientOrderStatus = {
  CANCELLED: 0,
  CREATED: 1,
  IN_PREPARATION: 2,
  IN_DELIVERY: 3,
  FINISHED: 4,
};

export const ClientOrderStatusLabel = {
  [ClientOrderStatus.CANCELLED]: 'Cancelled',
  [ClientOrderStatus.CREATED]: 'Created',
  [ClientOrderStatus.IN_PREPARATION]: 'In Preparation',
  [ClientOrderStatus.IN_DELIVERY]: 'In Delivery',
  [ClientOrderStatus.FINISHED]: 'Finished',
};

export class ClientOrder {
  constructor() {
    /** @type {number} */
    this.id = 0;

    /** @type {string} */
    this.clientName = '';

    /** @type {number} */
    this.subtotal = 0;

    /** @type {number} */
    this.taxes = 0;

    /** @type {number} */
    this.shipping = 0;

    /** @type {number} */
    this.total = 0;

    /** @type {string} */
    this.createdAt = 0;

    /** @type {number} */
    this.regStatus = 0;

    /** @type {ClientOrderProduct[]} */
    this.products = [];

    /** @type {?ClientOrderDelivery} */
    this.delivery = null;

    /** @type {?ClientOrderPayment} */
    this.payment = null;

    /** @type {DeliveryOrderPackage[]} */
    this.packages = [];
  }

  get images() {
    return this.products.flatMap(item => item.imageUrl) ?? [];
  }

  get regStatusLabel() {
    if (Object.keys(ClientOrderStatusLabel).includes(this.regStatus.toString())) {
      return ClientOrderStatusLabel[this.regStatus];
    }

    return 'Undefined';
  }
}
